<template>
  <div class="animated fadeIn">
    <div class="main-top-wrap">
      <div class="top-banner-slide">
        <div class="banner-slide" style="background-image:url('/img/img_top_banner_1.png')">
          <div class="text-wrap">
            <span class="top-txt-1"></span>
            <span class="top-txt-2">당신의 모든 비즈니스를 <br />맡겨주세요!</span>
            <span class="top-txt-3">스피드문자와 함께하면 비즈니스가 더 쉽고 편해집니다.</span>
            <a href="https://t.me/skt0123" target="_blank">
              <span class="btn-shop" style="width:300px;display: flex;align-items: center;justify-content: center;">
                <img src="/img/logo_telegram.png" style="margin-right:10px;width:50px" /><span>텔레그램 @SKT0123</span>
              </span>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="section-2">
      <div class="guide-wrap">
        <div class="txt-title-box">
          <span>
            <em>스피드문자를<br />소개합니다!</em>
            <svg
              class="icon-svg"
              version="1.1"
              width="140"
              height="20"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
            >
              <path
                fill="none"
                fill-rule="evenodd"
                stroke="#F7444E"
                stroke-linecap="round"
                stroke-width="3"
                class="draw-line"
                d="M2 10c4-3.333 8-5 12-5 6 0 14 6 19 6s15-7 24-7 18 6 26 6 13-6 20-6 16 6 25 6 13-6 18-6 12 6 21 5c6-.667 12.333-3 19-7"
              />
            </svg>
          </span>
        </div>
        <span class="txt-sb-tit">국제문자 발송을 편하게 할 수 있도록<br />여러 서비스를 제공해요.</span>
        <div class="service-cont">
          <a href="#" class="item-box pot-1 scrollMotion">
            <span class="svc-txt-1">해외 발신번호 사용</span>
            <span class="svc-txt-2"
              >마케팅전용 발신번호를 자동채택하여 발송하기 때문에<br />복잡한 발신번호 관리가 필요없습니다.</span
            >
          </a>
          <a href="#" class="item-box pot-2 scrollMotion">
            <span class="svc-txt-1">2배 길어진 문자</span>
            <span class="svc-txt-2"
              >일반 단문문자보다 약 2배 정도 길게 문자 작성이 가능합니다.<br />(한글, 영문 70자까지)</span
            >
          </a>
          <a href="#" target="_blank" class="item-box pot-3 scrollMotion">
            <span class="svc-txt-1">높은 발송률과 빠른 발송 속도</span>
            <span class="svc-txt-2"
              >국제망을 사용하여 병렬구조로 문자를 발송하기 때문에<br />
              처리 속도가 매우 빠릅니다.</span
            >
          </a>
          <a href="#" class="item-box pot-4 scrollMotion">
            <span class="svc-txt-1">완벽한 보안</span>
            <span class="svc-txt-2"
              >엔터프라즈급 보안을 위해<br />아마존 AWS를 사용하여 고객의 소중한 데이터를 보호합니다</span
            >
          </a>
          <a href="#" class="item-box pot-5 scrollMotion">
            <span class="svc-txt-1">연중무휴 24시간 지원</span>
            <span class="svc-txt-2">365일 24시간 고객센터를 운영합니다.</span>
          </a>
        </div>
      </div>
    </div>
    <div class="banner-wrap">
      <div class="guide-wrap">
        <span class="txt-sb-tit" style="padding:0">가장 쉽고 간편한 국제문자발송</span>
        <span class="txt-title-box">지금 바로 시작하세요</span>
        <a href="https://t.me/skt0123" target="_blank">
          <span
            class="btn-shop"
            style="margin:30px auto 0;width:300px;display: flex;align-items: center;justify-content: center;"
          >
            <img src="/img/logo_telegram.png" style="margin-right:10px;width:50px" /><span>텔레그램 @SKT0123</span>
          </span>
        </a>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Home',
  mounted() {
    document.getElementsByClassName('app-body')[0].classList.add('bg-white')
    document.getElementsByClassName('main')[0].classList.remove('pt-4')
    document.getElementsByClassName('container-fluid')[0].classList.add('full-container')
    document.getElementsByClassName('main')[0].setAttribute('style', 'padding-bottom: 0 !important')
  },
  destroyed() {
    document.getElementsByClassName('main')[0].classList.add('pt-4')
    document.getElementsByClassName('container-fluid')[0].classList.remove('full-container')

    document.getElementsByClassName('main')[0].removeAttribute('style')
  },
}
</script>
<style scoped>
html {
  overflow-y: hidden;
}

div.main_header,
div#top_header *,
div#sub_header *,
div.location_box *,
div.main_top_menu * {
  font-family: Malgun Gothic, '맑은 고딕', AppleGothic, Dotum, '돋움', sans-serif;
}

/*메인*/
.main_header {
  width: 100%;
  height: 95px;
  border-bottom: 1px solid #e0e0e0;
  position: relative;
  z-index: 300;
}
.main_header.nounder {
  border-bottom: 0 none;
}
.main_header_top {
  position: relative;
  width: 990px;
  height: 95px;
  margin: 0 auto;
  z-index: 1000;
}
.main_header_top h1 {
  position: absolute;
  left: 0;
  top: 30px;
  width: 1000px;
  height: 60px;
  margin: 0;
  padding: 0;
  text-align: left;
}
.main_header_top h1 a:link {
  text-decoration: none;
}
.main_header_top h1 a:visited {
  text-decoration: none;
}
.main_header_top h1 a:hover {
  text-decoration: none;
}

.main_header_top h1 img {
  vertical-align: top;
}

.main_top_menu {
  position: absolute;
  right: 0;
  top: 10px;
  line-height: 1em;
  font-size: 13px !important;
}
.main_top_menu ul li {
  float: left;
  margin-left: 10px;
  padding-left: 10px;
  background: url('//img.godo.co.kr/godomall/header/common/ico_bar.png') no-repeat left 4px;
}
.main_top_menu ul li:first-child {
  background: none;
}
.main_top_menu ul li img {
  position: relative;
  top: 2px;
}
.main_top_menu ul li a {
  font-size: 12px;
  color: #666;
  letter-spacing: -0.5px;
}
.main_top_menu ul li a:link {
  text-decoration: none;
}
.main_top_menu ul li a:visited {
  text-decoration: none;
}
.main_top_menu ul li a:hover {
  text-decoration: underline;
}

.main_gnb_menu {
  position: absolute;
  right: 0;
  top: 53px;
  height: 16px;
}
.main_gnb_menu ul {
  height: 42px;
}
.main_gnb_menu ul li {
  float: left;
  display: inline-block;
  margin: 0 0 0 32px;
}
.main_gnb_menu ul li a {
  display: inline-block;
  height: 37px;
  overflow: hidden;
}
.main_gnb_menu ul li a.on img {
  margin-top: -84px;
}

/*.main_gnb_menu li a{height: 33px;}*/
.main_gnb_menu li a.on {
  border-bottom: 5px solid #fa2828;
}
/*서브*/
#sub_header {
  position: relative;
  width: 100%;
  height: 90px;
  border-bottom: 1px solid #e0e0e0;
  z-index: 300;
}
#top_header {
  background-color: #fafafb;
  border-bottom: 1px solid #e0e0e0;
}
.sub_box {
  position: relative;
  width: 990px;
  height: 35px;
  margin: 0 auto;
  line-height: 1em;
}
.edu .sub_box {
  width: 1120px;
}
.sub_dep01 {
  position: absolute;
  left: 0;
  height: 35px;
}
.sub_dep01 ul {
  height: 35px;
}
.sub_dep01 ul li {
  float: left;

  margin-top: 0;
  padding-left: 0;
  line-height: 1em;
  color: inherit;
  background: none;
}
.sub_dep01 ul li.on {
  border-top: 2px solid #fa2828;
}
.sub_dep01 ul li a {
  display: inline-block;
  height: 34px;
  line-height: 34px;
  font-size: 12px;
  color: #878787;
  text-align: center;
  padding: 0 15px 0 15px;
  letter-spacing: -0.5px;
}
.sub_dep01 ul li a:hover {
  text-decoration: underline;
}
.sub_dep01 ul li.on a:hover {
  text-decoration: none;
}
.sub_dep01 ul li.on a {
  color: #181818;
  background-color: #fff;
  font-weight: bold;
  border-left: 1px solid #e0e0e0;
  border-right: 1px solid #e0e0e0;
  line-height: 30px;
}

.sub_top_menu {
  position: absolute;
  right: 0;
  top: 7px;
  line-height: 1em;
}
/*.edu .sub_top_menu{top: 9px;}*/
.sub_top_menu ul li {
  float: left;
  margin-left: 9px;
  padding-left: 10px;
  background: url('//img.godo.co.kr/godomall/header/common/ico_bar.png') no-repeat left 7px;
  margin-top: 0;
  line-height: 21px;
}
.sub_top_menu ul li:first-child {
  background: none;
}
.sub_top_menu ul li img {
  position: relative;
  top: 1px;
  vertical-align: top;
}
.sub_top_menu ul li a {
  font-size: 12px;
  color: #757575;
  letter-spacing: -0.5px;
}
.sub_top_menu ul li a.on {
  font-weight: bold;
  color: #fa2828;
}
.sub_top_menu ul li a:hover {
  text-decoration: underline;
}

.sub_gnb {
  position: relative;
  width: 990px;
  height: 90px;
  margin: 0 auto;
}
.edu .sub_gnb {
  width: 1120px;
}
.sub_gnb h1 {
  display: inline-block;
  position: absolute;
  top: 32px;
  left: 0;
  margin: 0;
  padding: 0;
}
.sub_gnb h1 a {
  text-decoration: none;
  float: left;
}
.sub_gnb h1 a:visited {
  text-decoration: none;
}
.sub_gnb h1 a:link {
  text-decoration: none;
}
.sub_gnb h1 a:hover {
  text-decoration: none;
}
.sub_gnb .main_slogan {
  display: inline-block;
  float: left;
  margin: 0 0 0 5px;
}
.sub_gnb .main_slogan img {
  display: none;
}
.echost .main_slogan .h_echost {
  display: inline-block;
}
.cloud .main_slogan .h_cloud {
  display: inline-block;
}
.hosting .main_slogan .h_hosting {
  display: inline-block;
}
.domain .main_slogan .h_domain {
  display: inline-block;
}
.design .main_slogan .h_design {
  display: inline-block;
}
.marketing .main_slogan .h_marketing {
  display: inline-block;
}
.edu .main_slogan .h_edu {
  display: inline-block;
}
.mygodo .main_slogan .h_mygodo {
  display: inline-block;
}
.customer .main_slogan .h_customer {
  display: inline-block;
}

.sub_dep02 {
  position: absolute;
  right: 0;
  top: 38px;
  z-index: 9999;
}
.sub_dep02 ul li {
  float: left;
  margin: 0 0 0 25px;

  padding-left: 0;
  background: none;
}
.sub_dep02 ul li:first-child {
  background: url('//img.godo.co.kr/godomall/header/sub/ico_bar.png') no-repeat right top;
  padding: 0 20px 0 0px;
  margin: 0px -5px 0 0px;
}

.sub_dep02 ul li a {
  display: block;
  height: 15px;
  overflow: hidden;
}
.edu .sub_dep02 ul li a {
  height: 26px;
}
/*.design .sub_dep02 li a{height: 19px;}*/
/*.sub_dep02 ul li.academy_month a {height: 16px;}
.sub_dep02 ul li.academy_fast a{height: 26px;}
.sub_dep02 .academy_fast{display: inline-block;height: 26px;overflow: hidden;position: absolute;right: 0;top: -5px;}
.sub_dep02 .academy_fast.active img{margin-top: 0;}
.sub_dep02 .academy_fast img{margin-top: -100px;}*/
.design .sub_dep02 ul li + li + li + li + li + li + li + li {
  margin-top: -3px;
}
.design .sub_dep02 ul li + li + li + li + li + li + li + li a {
  height: 19px;
}
.edu .sub_dep02 ul li + li + li + li + li + li + li + li {
  margin-top: -5px;
}

.sub_dep02.on li a {
  height: 33px;
}
.edu .sub_dep02.on li a {
  height: 33px;
}
.sub_dep02.on li a img {
  margin-top: -50px;
}

.sub_dep02 li a.on {
  border-bottom: 5px solid #fa2828;
}
.sub_dep02 li.nounder a.on {
  border-bottom: 0;
}

.sub_dep02.on li a.on img {
  margin-top: -100px;
}

/*디자인*/
.design .sub_dep02.on li + li + li + li + li + li + li + li a.on img {
  margin-top: -96px;
}

/* 마케팅 (서브메뉴가 모두 있기 때문에 nounder로 작업가능)*/
.marketing .sub_dep02 ul li.nounder {
  margin-top: -3px;
}
.marketing .sub_dep02 ul li.nounder a {
  height: 19px;
}
.marketing .sub_dep02.on li.nounder a.on img {
  margin-top: -96px;
}
/*아카데미*/
.edu .sub_dep02 li.edu_fast a.active img {
  margin-top: 0;
}
.edu .sub_dep02 li.edu_fast a img {
  margin-top: -100px;
}

.sub_dep02 li a.active img {
  margin-top: -100px;
}
.sub_dep02.on li a.active.on img {
  margin-top: -100px;
}
.sub_dep02.on li a.active img {
  margin-top: -50px;
}
/*마이고도*/
#sub_header.mygodo .sub_dep02.on li a {
  height: 15px;
}
#sub_header.mygodo .sub_dep02.on li a img {
  margin-top: 0;
}
#sub_header.mygodo .sub_dep02.on li a.on img {
  margin-top: -24px;
}
#sub_header.mygodo .sub_dep02 li a.active img {
  margin-top: -24px;
}
/*고객센터*/
#sub_header.customer .sub_dep02.on li a {
  height: 15px;
}
#sub_header.customer .sub_dep02.on li a img {
  margin-top: 0;
}
#sub_header.customer .sub_dep02.on li a.on img {
  margin-top: -24px;
}
#sub_header.customer .sub_dep02 li a.active img {
  margin-top: -24px;
}
/*각각의 메뉴처리*/
#sub_header .sub_dep02 ul {
  display: none;
}
#sub_header.echost .sub_dep02 .echost {
  display: block;
}
#sub_header.cloud .sub_dep02 .cloud {
  display: block;
}
#sub_header.hosting .sub_dep02 .hosting {
  display: block;
}
#sub_header.domain .sub_dep02 .domain {
  display: block;
}
#sub_header.design .sub_dep02 .design {
  display: block;
}
#sub_header.marketing .sub_dep02 .marketing {
  display: block;
}
#sub_header.edu .sub_dep02 .edu {
  display: block;
}
#sub_header.mygodo .sub_dep02 .mygodo {
  display: block;
}
#sub_header.mygodo .sub_dep02 ul.mygodo li:first-child {
  background: none;
  padding: 0;
  margin: 0;
}
#sub_header.customer .sub_dep02 .customer {
  display: block;
}
#sub_header.customer .sub_dep02 ul.customer li:first-child {
  background: none;
  padding: 0;
  margin: 0;
}

.main_header .all_menu_wrap {
  top: 94px;
}
/*전체메뉴공통*/
.all_menu_wrap {
  display: none;
  position: absolute;
  left: 0;
  top: 75px;
  width: 100%;
  z-index: 999;
  background-color: #fff;
  border-top: 1px solid #8a8a8a;
  border-bottom: 1px solid #8a8a8a;
  background-color: #fdfdfd;
}
.all_menu_wrap.on {
  display: block;
}
.all_menu_box > li:first-child {
  margin: 0;
}
.all_menu_box {
  width: 990px;
  margin: 40px auto 50px;
  height: 100%;
  overflow: hidden;
}
.edu .all_menu_box {
  width: 1120px;
}
.main_header .edu .all_menu_box {
  width: 990px;
}
.all_menu_box > li {
  float: left;
  width: 146px;
}
.all_menu_box h2 {
  height: 25px;
  border-bottom: 1px solid #fa2828;
  overflow: hidden;
  text-align: left;
  margin: 0;
}
.all_menu_box h2 .ico_new {
  margin: -2px 0 0 5px;
}
.all_menu_box ul li {
  display: block;
  float: none;
  margin: 16px 0 0 0;
  text-align: left;
  position: relative;
} /*20200211 선착순 무료 아이콘 추가*/
.all_menu_box ul li img {
  position: relative;
  top: 0px;
  vertical-align: top;
}
.all_menu_box a {
  font-size: 13px;
  color: #686868;
  letter-spacing: -0.5px;
  text-decoration: none;
}
.all_menu_box a:hover {
  text-decoration: underline;
  color: #686868;
}
.all_menu_box a:visited {
  color: #686868;
}

/*전체메뉴 쇼핑몰*/
.all_menu_wrap.echost .all_menu_box > li {
  width: 146px;
  margin: 0 0 0 22px;
}
.all_menu_wrap.echost .all_menu_box ul li img {
  position: absolute;
  top: -4px;
  right: -4px;
} /*20200211 선착순 무료 아이콘 추가*/
/*전체메뉴 클라우드*/
.all_menu_wrap.cloud .all_menu_box > li {
  width: 180px;
  margin: 0 0 0 22px;
}
/*전체메뉴 호스팅*/
.all_menu_wrap.hosting .all_menu_box > li {
  width: 146px;
  margin: 0 0 0 22px;
}
/*전체메뉴 도메인*/
.all_menu_wrap.domain .all_menu_box > li {
  width: 231px;
  margin: 0 0 0 22px;
}
/*전체메뉴 디자인*/
.all_menu_wrap.design .all_menu_box > li {
  width: 146px;
  margin: 0 0 0 22px;
}
/*전체메뉴 마케팅*/
.all_menu_wrap.marketing .all_menu_box > li {
  width: 146px;
  margin: 0 0 0 22px;
}
/*전체메뉴 아카데미*/
.all_menu_wrap.edu .all_menu_box > li {
  width: 206px;
  margin: 0 0 0 22px;
}
/*메인전체메뉴 아카데미*/
.main_header .edu .all_menu_box > li {
  width: 122px;
  margin: 0 0 0 22px;
}
/*전체메뉴공통*/
.all_menu_wrap .all_menu_box > li:first-child {
  margin: 0;
}

/*3depth*/
.sub_dep03 {
  display: none;
  position: absolute;
  left: 0;
  top: 75px;
  width: 100%;
  height: 195px;
  z-index: 999;
  background-color: #fff;
  border-top: 1px solid #8a8a8a;
  border-bottom: 1px solid #8a8a8a;
  background-color: #fdfdfd;
}
.sub_dep03.on {
  display: block;
}
.sub_dep03.echost_solution .dep03_menu ul li img {
  top: -5px;
  right: 3px;
} /*20200211 선착순 무료 아이콘 추가*/

.dep03_box {
  width: 990px;
  margin: 0 auto;
  height: 100%;
}
.edu .dep03_box {
  width: 1120px;
}
.dep03_title {
  float: left;
  width: 199px;
  height: 100%;
  border-right: 1px solid #e4e4e4;
}
.edu .dep03_title {
  width: 211px;
}
.dep03_title img {
  margin: 30px 0 0 0;
}
.dep03_menu {
  float: left;
  width: 589px;
  height: 100%;
  background-color: #fff;
}
.edu .dep03_menu {
  width: 708px;
}
.dep03_menu ul {
  display: inline-block;
  margin: 30px 0 0 48px;
  height: 109px;
}
.edu .dep03_menu ul {
  margin: 30px 0 0 70px;
}
.dep03_menu ul li {
  float: left;
  width: 180px;
  margin: 0 0 17px 0;
}
.dep03_menu ul li img {
  position: relative;
  top: 0px;
  vertical-align: top;
}
.dep03_menu ul li a {
  font-size: 13px;
  color: #444;
  letter-spacing: -0.5px;
}
.dep03_menu ul li a:hover {
  text-decoration: underline;
}

.dep03_menu dl {
  margin: 0 0 0 48px;
}
.dep03_menu dt {
  float: left;
}
.dep03_menu dd {
  float: left;
  margin: 0 0 0 5px;
}
.dep03_menu dd a:hover {
  text-decoration: underline;
}
.dep03_menu .godo_color {
  color: #fa2828;
}
.dep03_box > a {
  float: right;
}

.menu_shadow {
  position: absolute;
  left: 0;
  bottom: -16px;
  width: 100%;
  height: 15px;
  background: url('//img.godo.co.kr/godomall/header/sub/bg_shadow.png') repeat-x left top;
}

/*로케이션바*/
.location_wrap {
  width: 100%;
  height: 42px;
  border-bottom: 1px solid #e0e0e0;
}
.location_box {
  position: relative;
  width: 990px;
  height: 29px;
  margin: 0 auto;
  padding: 13px 0 0 0;
}
.edu .location_box {
  width: 1120px;
}
.location_box ul li {
  float: left;
  font-size: 12px;
  color: #a0a0a0;
  background: url('//img.godo.co.kr/godomall/header/sub/ico_right.png') no-repeat left 5px;
  padding: 0 10px 0 14px;
  letter-spacing: -0.5px;
  line-height: 1.3em;
}
.location_box ul li:first-child {
  background: url('//img.godo.co.kr/godomall/header/sub/ico_colon.png') no-repeat left 1px;
  padding: 0 10px 0 24px;
}
.location_box ul li a {
  color: #a0a0a0;
}
.location_box ul li a:hover {
  text-decoration: underline;
}
.location_box .view {
  font-weight: bold;
  color: #3a3a3a;
}

/*
	전체 서비스 레이어
*/
div#allService {
  display: none;
  position: absolute;
  z-index: 99999;
  top: 0;
  left: 0;
  width: 100%;
  min-width: 990px;
  height: 771px;
  background: url('//img.godo.co.kr/godomall/header/allservice/bg_layer_wrap.gif') repeat-x left top;
}
div#allService.show {
  display: block;
}
div.all-service {
  overflow: hidden;
  position: relative;
  top: 2px;
  z-index: 300;
  width: 990px;
  height: 767px;
  margin: 0 auto;
}
div.all-service * {
  font-family: Malgun Gothic, '맑은 고딕', AppleGothic, Dotum, '돋움', sans-serif;
}
div.all-service div.layer-wrap {
  position: relative;
}
div.all-service div.layer-wrap button.close {
  position: absolute;
  top: 20px;
  right: 345px;
  width: 24px;
  height: 24px;
  background: url('//img.godo.co.kr/godomall/header/allservice/close_layer.gif') no-repeat left top;
  text-indent: -9999px;
}
div.all-service div.layer-wrap h2 {
  height: 44px;
  padding: 15px 0 0;
  color: #222;
  font-size: 22px;
  text-align: center;
}
div.all-service div.layer-wrap div.cnt-wrap {
  position: relative;
  background: #fff;
}
div.all-service div.layer-wrap div.cnt-wrap:after {
  content: '';
  display: block;
  clear: both;
}
div.all-service div.layer-wrap div.cnt-wrap div.left {
  float: left;
  width: 217px;
  margin-top: -1px;
}
div.all-service div.layer-wrap div.cnt-wrap div.left ul {
}
div.all-service div.layer-wrap div.cnt-wrap div.left ul li {
  width: 100%;
}
div.all-service div.layer-wrap div.cnt-wrap div.left ul li a {
  display: block;
  width: 133px;
  height: 66px;
  padding: 21px 0 0 68px;
  background: #5b5b5b;
  color: #fff;
  font-size: 13px;
  letter-spacing: -1px;
  line-height: 20px;
}
div.all-service div.layer-wrap div.cnt-wrap div.left ul li a.tab1 {
  height: 54px;
  padding-top: 32px;
  background: #8d8d8d url('//img.godo.co.kr/godomall/header/allservice/ico_tab_1.gif') no-repeat left top;
}
div.all-service div.layer-wrap div.cnt-wrap div.left ul li.on a.tab1 {
  background-position: right top;
}
div.all-service div.layer-wrap div.cnt-wrap div.left ul li a.tab2 {
  background: url('//img.godo.co.kr/godomall/header/allservice/ico_tab_2.gif') no-repeat left top;
}
div.all-service div.layer-wrap div.cnt-wrap div.left ul li.on a.tab2 {
  background-position: right top;
}
div.all-service div.layer-wrap div.cnt-wrap div.left ul li a.tab3 {
  background: url('//img.godo.co.kr/godomall/header/allservice/ico_tab_3.gif') no-repeat left top;
}
div.all-service div.layer-wrap div.cnt-wrap div.left ul li.on a.tab3 {
  background-position: right top;
}
div.all-service div.layer-wrap div.cnt-wrap div.left ul li a.tab4 {
  background: url('//img.godo.co.kr/godomall/header/allservice/ico_tab_4.gif') no-repeat left top;
}
div.all-service div.layer-wrap div.cnt-wrap div.left ul li.on a.tab4 {
  background-position: right top;
}
div.all-service div.layer-wrap div.cnt-wrap div.left ul li a.tab5 {
  background: url('//img.godo.co.kr/godomall/header/allservice/ico_tab_5.gif') no-repeat left top;
}
div.all-service div.layer-wrap div.cnt-wrap div.left ul li.on a.tab5 {
  background-position: right top;
}
div.all-service div.layer-wrap div.cnt-wrap div.left ul li a.tab6 {
  background: url('//img.godo.co.kr/godomall/header/allservice/ico_tab_6.gif') no-repeat left top;
}
div.all-service div.layer-wrap div.cnt-wrap div.left ul li.on a.tab6 {
  background-position: right top;
}
div.all-service div.layer-wrap div.cnt-wrap div.left ul li a.tab7 {
  background: url('//img.godo.co.kr/godomall/header/allservice/ico_tab_7.gif') no-repeat left top;
}
div.all-service div.layer-wrap div.cnt-wrap div.left ul li.on a.tab7 {
  background-position: right top;
}
div.all-service div.layer-wrap div.cnt-wrap div.left ul li.on {
  background: url('//img.godo.co.kr/godomall/header/allservice/tab_on.png') no-repeat right top;
}
div.all-service div.layer-wrap div.cnt-wrap div.left div {
  overflow: hidden;
  width: 201px;
  height: 100px;
  background: #5b5b5b;
  border-top: 1px solid #4d4d4d;
}
div.all-service div.layer-wrap div.cnt-wrap div.left div img {
  display: block;
  margin: 27px 0 0 27px;
}
div.all-service div.layer-wrap div.cnt-wrap div.left div a {
  float: left;
  margin: 14px 0 0;
  padding: 0 8px;
  background: #5b5b5b url('//img.godo.co.kr/godomall/header/allservice/cs_bar.gif') no-repeat left 3px;
  color: #fff;
  font-size: 11px;
}
div.all-service div.layer-wrap div.cnt-wrap div.left div a.fir {
  margin-left: 25px;
  background: none;
}
div.all-service div.layer-wrap div.cnt-wrap div.right {
  float: left;
  width: 751px;
  padding: 20px 0 0 22px;
  text-align: left;
}
div.all-service div.layer-wrap div.cnt-wrap div.right > div#asCnt1 a {
  font-family: Malgun Gothic, '맑은 고딕', AppleGothic, Dotum, '돋움', sans-serif;
}
div.all-service div.layer-wrap div.cnt-wrap div.right > div#asCnt1 > ul {
  overflow: hidden;
  width: 752px;
  margin-left: -1px;
}
div.all-service div.layer-wrap div.cnt-wrap div.right > div#asCnt1 > ul > li {
  float: left;
  width: 150px;
  height: 220px;
  padding: 0 38px 0 0;
  background: none;
  text-align: left;
}
div.all-service div.layer-wrap div.cnt-wrap div.right > div#asCnt1 > ul > li > a {
  color: #222;
  font-size: 16px;
  font-weight: bold;
}
div.all-service div.layer-wrap div.cnt-wrap div.right > div#asCnt1 > ul > li > strong {
  color: #555;
  font-size: 16px;
  font-weight: normal;
}
div.all-service div.layer-wrap div.cnt-wrap div.right > div#asCnt1 > ul > li ul {
  margin: 7px 0 0;
  padding: 7px 0 25px;
  border-top: 2px solid #ddd;
}
div.all-service div.layer-wrap div.cnt-wrap div.right > div#asCnt1 > ul > li ul li {
  display: block;
  float: none;
  padding: 4px 0;
  background: none;
}
div.all-service div.layer-wrap div.cnt-wrap div.right > div#asCnt1 > ul > li ul li.line {
  margin: 4px 0 0;
  background: url('//img.godo.co.kr/godomall/common/dot_3x1_bbb.gif') repeat-x left top;
}
div.all-service div.layer-wrap div.cnt-wrap div.right > div#asCnt1 > ul > li ul li button {
  margin: 2px 0 0;
  width: 14px;
  height: 13px;
  background: url('//img.godo.co.kr/godomall/header/allservice/ico_favorite.gif') no-repeat left top;
  text-indent: -9999px;
}
div.all-service div.layer-wrap div.cnt-wrap div.right > div#asCnt1 > ul > li ul li a:hover {
  color: #fe5a58;
}
div.all-service div.layer-wrap div.cnt-wrap div.right > div#asCnt1 > ul > li ul li.on button {
  background: url('//img.godo.co.kr/godomall/header/allservice/ico_favorite.gif') no-repeat right top;
}
div.all-service div.layer-wrap div.cnt-wrap div.right > div#asCnt1 > ul > li ul li.on a {
  color: #fe5a58;
}
div.all-service div.layer-wrap div.cnt-wrap div.right > div > h3 {
  padding-bottom: 17px;
  border-bottom: 2px solid #ddd;
  color: #222;
  font-size: 16px;
}
div.all-service div.layer-wrap div.cnt-wrap div.right > div > h3 strong {
  color: #fe5a58;
  font-size: 16px;
}
div.all-service div.layer-wrap div.cnt-wrap div.right div#asCnt2 {
  width: 714px;
}
div.all-service div.layer-wrap div.cnt-wrap div.right div#asCnt2 li {
  background: none;
  text-align: left;
}
div.all-service div.layer-wrap div.cnt-wrap div.right > div > p {
  padding: 24px 0 0;
  color: #888;
}
div.all-service div.layer-wrap div.cnt-wrap div.right div#asCnt2 > ol {
  overflow: hidden;
  padding: 16px 0 0;
}
div.all-service div.layer-wrap div.cnt-wrap div.right div#asCnt2 > ol > li {
  float: left;
  width: 347px;
  height: 210px;
  margin: 0 0 15px;
}
div.all-service div.layer-wrap div.cnt-wrap div.right div#asCnt2 > ol > li.right {
  float: right;
}
div.all-service div.layer-wrap div.cnt-wrap div.right div#asCnt2 > ol > li h4 {
  height: 39px;
  padding: 12px 0 0 25px;
  background: #f9f9f9;
  border: 1px solid #b9b9b9;
  border-bottom: 1px solid #ddd;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  color: #fe5a58;
  font-size: 14px;
}
div.all-service div.layer-wrap div.cnt-wrap div.right div#asCnt2 > ol > li h4 img {
  margin-right: 10px;
  vertical-align: middle;
}
div.all-service div.layer-wrap div.cnt-wrap div.right div#asCnt2 > ol > li ul {
  height: 156px;
  padding: 0 0 0 26px;
  border: 1px solid #b9b9b9;
  border-top: none;
}
div.all-service div.layer-wrap div.cnt-wrap div.right div#asCnt2 > ol > li ul li {
  overflow: hidden;
  padding: 5px 0;
  letter-spacing: -1px;
}
div.all-service div.layer-wrap div.cnt-wrap div.right div#asCnt2 > ol > li ul li:first-child {
  padding-top: 27px;
}
div.all-service div.layer-wrap div.cnt-wrap div.right div#asCnt2 > ol > li ul li strong {
  float: left;
  padding-left: 10px;
  background: url('//img.godo.co.kr/godomall/header/allservice/bl_squre.gif') no-repeat left 8px;
  color: #222;
  font-size: 13px;
}
div.all-service div.layer-wrap div.cnt-wrap div.right div#asCnt2 > ol > li ul li span {
  float: left;
  color: #888;
}
div.all-service div.layer-wrap div.cnt-wrap div.right div#asCnt2 > ol > li ul.step1 strong {
  width: 84px;
}
div.all-service div.layer-wrap div.cnt-wrap div.right div#asCnt2 > ol > li ul.step2 strong {
  width: 111px;
}
div.all-service div.layer-wrap div.cnt-wrap div.right div#asCnt2 > ol > li ul.step3 strong {
  width: 122px;
}
div.all-service div.layer-wrap div.cnt-wrap div.right div#asCnt2 > ol > li ul.step4 strong {
  width: 106px;
}
div.all-service div.layer-wrap div.cnt-wrap div.right div#asCnt2 > div {
  overflow: hidden;
  padding: 18px 0;
  background: #f7f7f8;
  border: 1px solid #e1e4eb;
}
div.all-service div.layer-wrap div.cnt-wrap div.right div#asCnt2 > div h4 {
  float: left;
  padding: 0 0 0 30px;
  color: #888;
  font-size: 19px;
}
div.all-service div.layer-wrap div.cnt-wrap div.right div#asCnt2 > div a {
  float: left;
  margin-left: 35px;
  text-align: center;
}
div.all-service div.layer-wrap div.cnt-wrap div.right div#asCnt3 li {
  background: none;
  text-align: left;
}
div.all-service div.layer-wrap div.cnt-wrap div.right div#asCnt3 > ol {
  overflow: hidden;
  padding: 28px 0 0;
}
div.all-service div.layer-wrap div.cnt-wrap div.right div#asCnt3 > ol > li {
  float: left;
  width: 229px;
  margin: 0 0 14px 13px;
}
div.all-service div.layer-wrap div.cnt-wrap div.right div#asCnt3 > ol > li.fir {
  margin-left: 0;
}
div.all-service div.layer-wrap div.cnt-wrap div.right div#asCnt3 > ol > li div.tit {
  height: 50px;
  padding: 16px 0 0 10px;
  background: #f9f9f9;
  border: 1px solid #b9b9b9;
  border-bottom: 1px solid #ddd;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
div.all-service div.layer-wrap div.cnt-wrap div.right div#asCnt3 > ol > li div.tit h4 {
  padding-left: 34px;
  color: #fe5a58;
  font-size: 14px;
  letter-spacing: -1px;
  text-indent: -34px;
}
div.all-service div.layer-wrap div.cnt-wrap div.right div#asCnt3 > ol > li div.tit h4 img {
  margin-right: 10px;
  vertical-align: middle;
}
div.all-service div.layer-wrap div.cnt-wrap div.right div#asCnt3 > ol > li div.tit p {
  padding: 3px 0 0;
}
div.all-service div.layer-wrap div.cnt-wrap div.right div#asCnt3 > ol > li div.build-cnt {
  height: 195px;
  padding: 21px 0 0 19px;
  border: 1px solid #b9b9b9;
  border-top: none;
}
div.all-service div.layer-wrap div.cnt-wrap div.right div#asCnt3 > ol > li div.build-cnt p {
  padding: 0 0 3px;
  color: #888;
  letter-spacing: -1px;
}
div.all-service div.layer-wrap div.cnt-wrap div.right div#asCnt3 > ol > li div.build-cnt a {
  display: inline-block;
  margin-bottom: 15px;
  padding-right: 12px;
  background: url('//img.godo.co.kr/godomall/header/allservice/arrow_link.gif') no-repeat right 7px;
  color: #222;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: -1px;
}
div.all-service div.layer-wrap div.cnt-wrap div.right div#asCnt3 > ol > li div.build-cnt a:hover {
  text-decoration: underline;
}
div.all-service div.layer-wrap div.cnt-wrap div.right div#asCnt3 > ol > li div.build-cnt a span {
  color: #888;
  font-weight: normal;
}
div.all-service div.layer-wrap div.cnt-wrap div.right div#asCnt3 > ol > li div.build-cnt ul {
}
div.all-service div.layer-wrap div.cnt-wrap div.right div#asCnt3 > ol > li div.build-cnt ul li {
  padding: 0 0 5px 8px;
  color: #888;
  letter-spacing: -1px;
  line-height: 18px;
  text-indent: -8px;
}
div.all-service div.layer-wrap div.cnt-wrap div.right div#asCnt3 > ol > li div.build-cnt a.domain-search {
  margin: 20px 0 0 77px;
}
div.all-service div.layer-wrap div.cnt-wrap div.right div#asCnt4 {
  width: 714px;
}
div.all-service div.layer-wrap div.cnt-wrap div.right div#asCnt4 ul {
  overflow: hidden;
  padding: 28px 0 0;
}
div.all-service div.layer-wrap div.cnt-wrap div.right div#asCnt4 ul li {
  float: left;
  width: 229px;
  margin: 0 0 13px 13px;
}
div.all-service div.layer-wrap div.cnt-wrap div.right div#asCnt4 ul li div.tit {
  overflow: hidden;
  height: 50px;
  padding: 16px 0 0 10px;
  background: #f9f9f9;
  border: 1px solid #b9b9b9;
  border-bottom: 1px solid #ddd;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
div.all-service div.layer-wrap div.cnt-wrap div.right div#asCnt4 ul li div.tit img {
  float: left;
  margin: 6px 0 0;
}
div.all-service div.layer-wrap div.cnt-wrap div.right div#asCnt4 ul li div.tit div {
  float: left;
  padding: 0 0 0 8px;
}
div.all-service div.layer-wrap div.cnt-wrap div.right div#asCnt4 ul li div.tit div span {
  display: block;
}
div.all-service div.layer-wrap div.cnt-wrap div.right div#asCnt4 ul li div.tit div strong {
  color: #fe5a58;
  font-size: 14px;
}
div.all-service div.layer-wrap div.cnt-wrap div.right div#asCnt4 ul li div.cnt {
  height: 195px;
  padding: 21px 0 0 13px;
  border: 1px solid #b9b9b9;
  border-top: none;
}
div.all-service div.layer-wrap div.cnt-wrap div.right div#asCnt4 ul li div.cnt p {
  color: #888;
  letter-spacing: -1px;
  line-height: 17px;
}
div.all-service div.layer-wrap div.cnt-wrap div.right div#asCnt4 ul li div.cnt a {
  display: inline-block;
  margin-top: 10px;
  padding-right: 12px;
  background: url('//img.godo.co.kr/godomall/header/allservice/arrow_link.gif') no-repeat right 8px;
  color: #222;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: -1px;
}
div.all-service div.layer-wrap div.cnt-wrap div.right div#asCnt4 ul li div.cnt a:hover {
  text-decoration: underline;
}
div.all-service div.layer-wrap div.cnt-wrap div.right div#asCnt4 ul li.pr1,
div.all-service div.layer-wrap div.cnt-wrap div.right div#asCnt4 ul li.pr4 {
  margin-left: 0;
}
div.all-service div.layer-wrap div.cnt-wrap div.right div#asCnt4 ul li.pr1 {
  background: url('//img.godo.co.kr/godomall/header/allservice/pr_banner_1.gif') no-repeat right bottom;
}
div.all-service div.layer-wrap div.cnt-wrap div.right div#asCnt4 ul li.pr2 {
  background: url('//img.godo.co.kr/godomall/header/allservice/pr_banner_2.gif') no-repeat right bottom;
}
div.all-service div.layer-wrap div.cnt-wrap div.right div#asCnt4 ul li.pr3 {
  background: url('//img.godo.co.kr/godomall/header/allservice/pr_banner_3.gif') no-repeat right bottom;
}
div.all-service div.layer-wrap div.cnt-wrap div.right div#asCnt4 ul li.pr4 {
  background: url('//img.godo.co.kr/godomall/header/allservice/pr_banner_4.gif') no-repeat right bottom;
}
div.all-service div.layer-wrap div.cnt-wrap div.right div#asCnt4 ul li.pr5 {
  background: url('//img.godo.co.kr/godomall/header/allservice/pr_banner_5.gif') no-repeat right bottom;
}
div.all-service div.layer-wrap div.cnt-wrap div.right div#asCnt4 ul li.pr6 {
  background: url('//img.godo.co.kr/godomall/header/allservice/pr_banner_6.gif') no-repeat right bottom;
}
div.all-service div.layer-wrap div.cnt-wrap div.right div#asCnt5 {
  width: 714px;
}
div.all-service div.layer-wrap div.cnt-wrap div.right div#asCnt5 div.container {
  overflow: hidden;
  padding: 28px 0 0;
}
div.all-service div.layer-wrap div.cnt-wrap div.right div#asCnt5 div.container div.cnt {
  float: right;
}
div.all-service div.layer-wrap div.cnt-wrap div.right div#asCnt5 div.container div.cnt:first-child {
  float: left;
}
div.all-service div.layer-wrap div.cnt-wrap div.right div#asCnt5 div.container div.cnt div {
  width: 344px;
  height: 66px;
  padding: 20px 0 0 0;
  background: #f9f9f9;
  border: 1px solid #b9b9b9;
  border-bottom: 1px solid #ddd;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  letter-spacing: -1px;
  text-align: center;
}
div.all-service div.layer-wrap div.cnt-wrap div.right div#asCnt5 div.container div.cnt div p {
  padding: 0 0 5px;
}
div.all-service div.layer-wrap div.cnt-wrap div.right div#asCnt5 div.container div.cnt div strong {
  color: #fe5a58;
  font-size: 18px;
}
div.all-service div.layer-wrap div.cnt-wrap div.right div#asCnt5 div.container div.cnt ul {
  width: 308px;
  height: 489px;
  padding: 6px 18px 0;
  background: url('//img.godo.co.kr/godomall/header/allservice/operate_dot.gif') no-repeat center 17px;
  border: 1px solid #b9b9b9;
  border-top: none;
}
div.all-service div.layer-wrap div.cnt-wrap div.right div#asCnt5 div.container div.cnt ul li {
  float: left;
  margin: 0 1px 1px 0;
  background: none;
}
div.all-service div.layer-wrap div.cnt-wrap div.right div#asCnt5 div.container div.cnt ul li a {
  display: block;
  width: 153px;
  height: 80px;
  padding: 81px 0 0;
  letter-spacing: -1px;
  text-align: center;
}
div.all-service div.layer-wrap div.cnt-wrap div.right div#asCnt5 div.container div.cnt ul li a strong {
  display: inline-block;
  padding: 0 0 5px;
  color: #222;
  font-size: 14px;
}
div.all-service div.layer-wrap div.cnt-wrap div.right div#asCnt5 div.container div.cnt ul li a span {
  color: #888;
  line-height: 16px;
}
div.all-service div.layer-wrap div.cnt-wrap div.right div#asCnt5 div.container div.cnt ul li a.operate-link1 {
  background: url('//img.godo.co.kr/godomall/header/allservice/operate_ico_1.gif') no-repeat center 17px;
}
div.all-service div.layer-wrap div.cnt-wrap div.right div#asCnt5 div.container div.cnt ul li a.operate-link2 {
  background: url('//img.godo.co.kr/godomall/header/allservice/operate_ico_2.gif') no-repeat center 17px;
}
div.all-service div.layer-wrap div.cnt-wrap div.right div#asCnt5 div.container div.cnt ul li a.operate-link3 {
  background: url('//img.godo.co.kr/godomall/header/allservice/operate_ico_3.gif') no-repeat center 17px;
}
div.all-service div.layer-wrap div.cnt-wrap div.right div#asCnt5 div.container div.cnt ul li a.operate-link4 {
  background: url('//img.godo.co.kr/godomall/header/allservice/ico_20150608.gif') no-repeat center 17px;
}
div.all-service div.layer-wrap div.cnt-wrap div.right div#asCnt5 div.container div.cnt ul li a.operate-link5 {
  background: url('//img.godo.co.kr/godomall/header/allservice/operate_ico_5.gif') no-repeat center 17px;
}
div.all-service div.layer-wrap div.cnt-wrap div.right div#asCnt5 div.container div.cnt ul li a.operate-link6 {
  background: url('//img.godo.co.kr/godomall/header/allservice/operate_ico_6.gif') no-repeat center 17px;
}
div.all-service div.layer-wrap div.cnt-wrap div.right div#asCnt5 div.container div.cnt ul li a.operate-link7 {
  background: url('//img.godo.co.kr/godomall/header/allservice/operate_ico_7.gif') no-repeat center 17px;
}
div.all-service div.layer-wrap div.cnt-wrap div.right div#asCnt5 div.container div.cnt ul li a.operate-link8 {
  background: url('//img.godo.co.kr/godomall/header/allservice/operate_ico_8.gif') no-repeat center 17px;
}
div.all-service div.layer-wrap div.cnt-wrap div.right div#asCnt5 div.container div.cnt ul li a.operate-link9 {
  background: url('//img.godo.co.kr/godomall/header/allservice/operate_ico_9.gif') no-repeat center 17px;
}
div.all-service div.layer-wrap div.cnt-wrap div.right div#asCnt5 div.container div.cnt ul li a.operate-link10 {
  background: url('//img.godo.co.kr/godomall/header/allservice/operate_ico_10.gif') no-repeat center 17px;
}
div.all-service div.layer-wrap div.cnt-wrap div.right div#asCnt5 div.container div.cnt ul li a.operate-link11 {
  background: url('//img.godo.co.kr/godomall/header/allservice/operate_ico_11.gif') no-repeat center 17px;
}
div.all-service div.layer-wrap div.cnt-wrap div.right div#asCnt5 div.container div.cnt ul li a.operate-link12 {
  background: url('//img.godo.co.kr/godomall/header/allservice/operate_ico_12.gif') no-repeat center 17px;
}
div.all-service div.layer-wrap div.cnt-wrap div.right div#asCnt5 div.container div.cnt ul li a.operate-link1:hover {
  background: url('//img.godo.co.kr/godomall/header/allservice/operate_ico_1.gif') no-repeat center -132px;
}
div.all-service div.layer-wrap div.cnt-wrap div.right div#asCnt5 div.container div.cnt ul li a.operate-link2:hover {
  background: url('//img.godo.co.kr/godomall/header/allservice/operate_ico_2.gif') no-repeat center -132px;
}
div.all-service div.layer-wrap div.cnt-wrap div.right div#asCnt5 div.container div.cnt ul li a.operate-link3:hover {
  background: url('//img.godo.co.kr/godomall/header/allservice/operate_ico_3.gif') no-repeat center -132px;
}
div.all-service div.layer-wrap div.cnt-wrap div.right div#asCnt5 div.container div.cnt ul li a.operate-link4:hover {
  background: url('//img.godo.co.kr/godomall/header/allservice/ico_20150608.gif') no-repeat center -132px;
}
div.all-service div.layer-wrap div.cnt-wrap div.right div#asCnt5 div.container div.cnt ul li a.operate-link5:hover {
  background: url('//img.godo.co.kr/godomall/header/allservice/operate_ico_5.gif') no-repeat center -132px;
}
div.all-service div.layer-wrap div.cnt-wrap div.right div#asCnt5 div.container div.cnt ul li a.operate-link6:hover {
  background: url('//img.godo.co.kr/godomall/header/allservice/operate_ico_6.gif') no-repeat center -132px;
}
div.all-service div.layer-wrap div.cnt-wrap div.right div#asCnt5 div.container div.cnt ul li a.operate-link7:hover {
  background: url('//img.godo.co.kr/godomall/header/allservice/operate_ico_7.gif') no-repeat center -132px;
}
div.all-service div.layer-wrap div.cnt-wrap div.right div#asCnt5 div.container div.cnt ul li a.operate-link8:hover {
  background: url('//img.godo.co.kr/godomall/header/allservice/operate_ico_8.gif') no-repeat center -132px;
}
div.all-service div.layer-wrap div.cnt-wrap div.right div#asCnt5 div.container div.cnt ul li a.operate-link9:hover {
  background: url('//img.godo.co.kr/godomall/header/allservice/operate_ico_9.gif') no-repeat center -132px;
}
div.all-service div.layer-wrap div.cnt-wrap div.right div#asCnt5 div.container div.cnt ul li a.operate-link10:hover {
  background: url('//img.godo.co.kr/godomall/header/allservice/operate_ico_10.gif') no-repeat center -132px;
}
div.all-service div.layer-wrap div.cnt-wrap div.right div#asCnt5 div.container div.cnt ul li a.operate-link11:hover {
  background: url('//img.godo.co.kr/godomall/header/allservice/operate_ico_11.gif') no-repeat center -132px;
}
div.all-service div.layer-wrap div.cnt-wrap div.right div#asCnt5 div.container div.cnt ul li a.operate-link12:hover {
  background: url('//img.godo.co.kr/godomall/header/allservice/operate_ico_12.gif') no-repeat center -132px;
}
div.all-service div.layer-wrap div.cnt-wrap div.right div#asCnt6 {
  width: 714px;
}
div.all-service div.layer-wrap div.cnt-wrap div.right div#asCnt6 div.faq {
  overflow: hidden;
  margin: 0 0 22px;
  padding: 20px 0 40px;
  border: 1px solid #b9b9b9;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
div.all-service div.layer-wrap div.cnt-wrap div.right div#asCnt6 div.faq.fir {
  margin-top: 36px;
}
div.all-service div.layer-wrap div.cnt-wrap div.right div#asCnt6 div.faq div {
  float: left;
  padding: 0 0 0 18px;
}
div.all-service div.layer-wrap div.cnt-wrap div.right div#asCnt6 div.faq div img {
  clear: left;
  float: left;
}
div.all-service div.layer-wrap div.cnt-wrap div.right div#asCnt6 div.faq div p.q {
  float: left;
  min-width: 465px;
  padding: 5px 0 15px 10px;
  color: #fe5a58;
  font-size: 14px;
  font-weight: bold;
}
div.all-service div.layer-wrap div.cnt-wrap div.right div#asCnt6 div.faq div p.a {
  float: left;
  min-width: 465px;
  padding: 2px 0 0 10px;
  line-height: 20px;
}
div.all-service div.layer-wrap div.cnt-wrap div.right div#asCnt6 div.faq a {
  float: right;
  width: 138px;
  height: 68px;
  margin: 15px 29px 0 0;
  padding: 25px 0 0 20px;
  background: url('//img.godo.co.kr/godomall/header/allservice/global_btn.gif') no-repeat left top;
  letter-spacing: -1px;
  line-height: 19px;
}

div.all-service div.layer-wrap div.cnt-wrap div.right div#asCnt6 div.faq a.manual {
  float: right;
  display: inline-block;
  width: 99px;
  height: 18px;
  margin: 0 22px 0 0;
  padding: 0;
  background: none;
  letter-spacing: -1px;
  line-height: 19px;
}
div.all-service div.layer-wrap div.cnt-wrap div.right div#asCnt6 div.faq a strong {
  font-size: 14px;
}
div.all-service div.layer-wrap div.cnt-wrap div.right div#asCnt6 div.faq a:hover {
  background: url('//img.godo.co.kr/godomall/header/allservice/global_btn_hover.gif') no-repeat left top;
  color: #fff;
}
div.all-service div.layer-wrap div.cnt-wrap div.right div#asCnt6 div.faq a:hover strong {
  color: #fff;
}
div.all-service div.layer-wrap div.cnt-wrap div.right div#asCnt7 {
  width: 714px;
}
div.all-service div.layer-wrap div.cnt-wrap div.right div#asCnt7 div.move1 {
  overflow: hidden;
  margin: 28px 0 60px;
}
div.all-service div.layer-wrap div.cnt-wrap div.right div#asCnt7 div.move1 ul {
  float: left;
  padding: 0 0 0 5px;
}
div.all-service div.layer-wrap div.cnt-wrap div.right div#asCnt7 div.move1 ul li {
  display: block;
  overflow: hidden;
  float: none;
  margin: 0 0 23px;
}
div.all-service div.layer-wrap div.cnt-wrap div.right div#asCnt7 div.move1 ul li img {
  float: left;
  margin: 2px 10px 0 0;
}
div.all-service div.layer-wrap div.cnt-wrap div.right div#asCnt7 div.move1 ul li p {
  float: left;
  font-size: 14px;
  line-height: 18px;
}
div.all-service div.layer-wrap div.cnt-wrap div.right div#asCnt7 div.move1 ul li p strong {
  color: #fe5a58;
  font-size: 14px;
}
div.all-service div.layer-wrap div.cnt-wrap div.right div#asCnt7 div.move1 div {
  float: right;
  width: 216px;
  margin: 0 23px 0 0;
}
div.all-service div.layer-wrap div.cnt-wrap div.right div#asCnt7 div.move1 div a {
  display: block;
}
div.all-service div.layer-wrap div.cnt-wrap div.right div#asCnt7 div.move1 div a.detail {
  width: 193px;
  height: 30px;
  margin: 13px 0 0;
  padding: 10px 0 0 23px;
  background: url('//img.godo.co.kr/godomall/header/allservice/move_btn.gif') no-repeat left top;
  font-size: 13px;
  letter-spacing: -1px;
}
div.all-service div.layer-wrap div.cnt-wrap div.right div#asCnt7 div.move1 div a.detail strong {
  color: #222;
}
div.all-service div.layer-wrap div.cnt-wrap div.right div#asCnt7 div.move2 {
}
div.all-service div.layer-wrap div.cnt-wrap div.right div#asCnt7 div.move2 > p {
  padding: 11px 0 0;
  color: #888;
  letter-spacing: -1px;
}
div.all-service div.layer-wrap div.cnt-wrap div.right div#asCnt7 div.move2 > ol {
  overflow: hidden;
  margin: 29px 0 0;
}
div.all-service div.layer-wrap div.cnt-wrap div.right div#asCnt7 div.move2 > ol > li.client {
  float: left;
  width: 171px;
  height: 139px;
}
div.all-service div.layer-wrap div.cnt-wrap div.right div#asCnt7 div.move2 > ol > li.client span {
  display: block;
  height: 30px;
  padding: 12px 0 0;
  background: #f9f9f9;
  border: 1px solid #b9b9b9;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom: 1px solid #ddd;
  font-size: 13px;
  text-align: center;
}
div.all-service div.layer-wrap div.cnt-wrap div.right div#asCnt7 div.move2 > ol > li.client p {
  height: 68px;
  padding: 26px 0 0;
  font-size: 13px;
  line-height: 17px;
  text-align: center;
  border: 1px solid #b9b9b9;
  border-top: none;
}
div.all-service div.layer-wrap div.cnt-wrap div.right div#asCnt7 div.move2 > ol > li.staff {
  float: right;
  width: 481px;
  padding-left: 31px;
  margin-right: 5px;
  background: url('//img.godo.co.kr/godomall/header/allservice/move_arrow_1.gif') no-repeat left 50%;
}
div.all-service div.layer-wrap div.cnt-wrap div.right div#asCnt7 div.move2 > ol > li.staff strong {
  display: block;
  height: 30px;
  padding: 12px 0 0;
  background: #f9f9f9;
  border: 1px solid #b9b9b9;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom: 1px solid #ddd;
  color: #fe5a58;
  font-size: 13px;
  text-align: center;
}
div.all-service div.layer-wrap div.cnt-wrap div.right div#asCnt7 div.move2 > ol > li.staff ol {
  overflow: hidden;
  height: 68px;
  padding: 26px 0 0;
  font-size: 13px;
  line-height: 16px;
  border: 1px solid #b9b9b9;
  border-top: none;
}
div.all-service div.layer-wrap div.cnt-wrap div.right div#asCnt7 div.move2 > ol > li.staff ol li {
  float: left;
  width: 105px;
  padding-right: 7px;
  background: url('//img.godo.co.kr/godomall/header/allservice/move_arrow_2.gif') no-repeat right 50%;
  letter-spacing: -1px;
  text-align: center;
}
div.all-service div.layer-wrap div.cnt-wrap div.right div#asCnt7 div.move2 > ol > li.staff ol li:first-child {
  margin-left: 30px;
}
div.all-service div.layer-wrap div.cnt-wrap div.right div#asCnt7 div.move2 > ol > li.staff ol li.last {
  margin-top: 6px;
  padding-right: 0;
  background: none;
}
div.all-service div.layer-wrap div.cnt-wrap div.right > div {
  display: none;
}
div.all-service div.layer-wrap div.cnt-wrap div.right > div.show {
  display: block;
}

/*
	gnb 스몰형
*/
div.header-simple {
  position: relative;
  z-index: 300;
  width: 100%;
  background: #fff;
  border-bottom: 1px solid #eceded;
}
div.header-simple div.link-wrap {
  overflow: hidden;
  width: 990px;
  margin: 0 auto;
  padding: 7px 0 9px;
}
div.header-simple div.leftsmall {
  float: left;
}
div.header-simple div.leftsmall a {
  float: left;
  padding: 0 6px;
  background: url('//img.godo.co.kr/godomall/header/bar_1x11_e1e2e2.gif') no-repeat left 4px;
}
div.header-simple div.leftsmall a:first-child {
  background: none;
  font-weight: bold;
}
div.header-simple div.leftsmall a img {
  vertical-align: -1px;
}
div.header-simple div.leftsmall a:hover {
  text-decoration: underline;
}
div.header-simple div.right {
  float: right;
}
div.header-simple div.right a {
  float: left;
  padding: 0 8px;
  background: url('//img.godo.co.kr/godomall/header/bar_1x11_e1e2e2.gif') no-repeat right 4px;
}
div.header-simple div.right a.all-service {
  padding: 0 0 0 26px;
  background: url('//img.godo.co.kr/godomall/header/headersimple_btn_allservice.gif') no-repeat 8px 3px;
  color: #ff5f5d;
  font-weight: bold;
}
div.header-simple div.right a:hover {
  text-decoration: underline;
}

/* 푸터 */
div.footer-wrap {
  width: 100%;
  border-top: 1px solid #f4f4f4;
  min-width: 990px;
  height: 290px;
}
div.footer-wrap * {
  font-family: Malgun Gothic, '맑은 고딕', AppleGothic, Dotum, '돋움', sans-serif;
}
div.footer-wrap div.contents {
  position: relative;
  overflow: hidden;
  height: 290px;
  width: 990px;
  margin: 0 auto;
}
div.footer-wrap div.contents div.link {
  text-align: left;
  margin-top: 40px;
  font-weight: bold;
}
div.footer-wrap div.contents div.link a {
  margin: 0 26px 0 0;
  font-weight: bold;
  font-size: 12px;
  color: #333333;
}
div.footer-wrap div.contents div.link a.privacy {
  color: #2188f1;
}
div.footer-wrap div.contents div.about address {
  margin: 16px 0 0;
  color: #888;
  font-size: 12px;
  line-height: 22px;
}
div.footer-wrap div.contents div.about address span {
  margin-left: 9px;
  padding-left: 10px;
  background: url('//img.godo.co.kr/godomall/footer/bar_address.gif') no-repeat left 4px;
  color: #888;
  line-height: 17px;
}
div.footer-wrap div.contents div.about address a {
  text-decoration: underline;
  color: #444444;
}
div.footer-wrap div.contents div.about address strong {
  color: #f91d11;
}
div.footer-wrap div.contents div.icon {
  float: left;
  margin-top: 25px;
}
div.footer-wrap div.contents div.icon a {
  margin-right: 20px;
}
div.footer-wrap div.contents div.footer-clear {
  clear: both;
  height: 0px;
}
div.footer-wrap div.contents div.isms {
  position: absolute;
  top: 40px;
  right: 0px;
}
div.footer-wrap div.contents div.isms a:first-child {
  margin: 0 10px 0 0;
}
/* 20141105 전체보기>해외시장 진출 컨텐츠 추가 - 태건 */
div.all-service div.layer-wrap div.cnt-wrap div.right div#asCnt6.faqupdate1 div.faq.fir a {
  margin-bottom: 20px;
}
div.all-service div.layer-wrap div.cnt-wrap div.right div#asCnt6.faqupdate1 div.faq.fir .faq-globalguide {
  clear: both;
  padding: 20px 25px 25px;
  margin-bottom: -30px;
  background: #f9f9f9;
  float: none;
}
div.all-service div.layer-wrap div.cnt-wrap div.right div#asCnt6.faqupdate1 div.faq.fir .faq-globalguide .faq-title {
  font-size: 14px;
  color: #222;
}
div.all-service
  div.layer-wrap
  div.cnt-wrap
  div.right
  div#asCnt6.faqupdate1
  div.faq.fir
  .faq-globalguide
  .faq-globalguide-step {
  overflow: hidden;
  margin: 25px 0 0 -5%;
}
div.all-service
  div.layer-wrap
  div.cnt-wrap
  div.right
  div#asCnt6.faqupdate1
  div.faq.fir
  .faq-globalguide
  .faq-globalguide-step
  li {
  float: left;
  position: relative;
  width: 15%;
  margin: 0 0 0 4.9%;
  text-align: center;
  font-weight: bold;
  color: #222;
}
div.all-service
  div.layer-wrap
  div.cnt-wrap
  div.right
  div#asCnt6.faqupdate1
  div.faq.fir
  .faq-globalguide
  .faq-globalguide-step
  .arr {
  position: absolute;
  top: 21px;
  left: 110%;
  display: inline-block;
  width: 7px;
  height: 12px;
  overflow: hidden;
}
div.all-service
  div.layer-wrap
  div.cnt-wrap
  div.right
  div#asCnt6.faqupdate1
  div.faq.fir
  .faq-globalguide
  .faq-globalguide-step
  .arr
  img {
  margin: -21px 0 0 -95px;
}
div.all-service
  div.layer-wrap
  div.cnt-wrap
  div.right
  div#asCnt6.faqupdate1
  div.faq.fir
  .faq-globalguide
  .faq-globalguide-step
  .icon {
  overflow: hidden;
  display: inline-block;
  zoom: 1;
  position: relative;
  width: 53px;
  height: 53px;
  margin-bottom: 15px;
}
div.all-service
  div.layer-wrap
  div.cnt-wrap
  div.right
  div#asCnt6.faqupdate1
  div.faq.fir
  .faq-globalguide
  .faq-globalguide-step
  .icon2
  img {
  margin-left: -143px;
}
div.all-service
  div.layer-wrap
  div.cnt-wrap
  div.right
  div#asCnt6.faqupdate1
  div.faq.fir
  .faq-globalguide
  .faq-globalguide-step
  .icon3
  img {
  margin-left: -286px;
}
div.all-service
  div.layer-wrap
  div.cnt-wrap
  div.right
  div#asCnt6.faqupdate1
  div.faq.fir
  .faq-globalguide
  .faq-globalguide-step
  .icon4
  img {
  margin-left: -429px;
}
div.all-service
  div.layer-wrap
  div.cnt-wrap
  div.right
  div#asCnt6.faqupdate1
  div.faq.fir
  .faq-globalguide
  .faq-globalguide-step
  .icon5
  img {
  margin-left: -572px;
}
div.all-service
  div.layer-wrap
  div.cnt-wrap
  div.right
  div#asCnt6.faqupdate1
  div.faq.fir
  .faq-globalguide
  .faq-globalguide-step
  .step {
  display: block;
  margin: 0 0 4px;
  color: #5583dd;
}

/*
	2015.04.29
	디자인 메뉴
*/
div.header-wrap li.design div.gnb-layer-container div.gnb-layer-contents ul.sub-menu > li {
  width: auto;
  padding: 0;
}
div.header-wrap li.design div.gnb-layer-container div.gnb-layer-contents ul.sub-menu > li + li a {
  margin: 0 0 0 50px;
}

div.header-wrap div.gnb-wrap div.gnb-v2 ul.left-menu > li.design div.gnb-layer-container.show,
div.header-wrap div.gnb-wrap div.gnb-v2 ul.left-menu > li.design div.gnb-layer-container.depth3-show {
  display: block !important;
  overflow: hidden !important;
  height: 42px !important;
  border-bottom: none !important;
}

/*20160502 윤태건 gnb hover 기능*/
.header-wrap .top-service h1 > a {
  float: left;
}
.top-service .godo-bi-a {
}
.top-service .godo-bi-b {
  position: relative;
}
.top-service .godo-bi-b .logo-hover {
  display: none;
}
.top-service .godo-bi-b:hover .logo-normal {
  display: none;
}
.top-service .godo-bi-b:hover .logo-hover {
  display: inline-block;
  *display: inline;
  *zoom: 1;
}

/* 160624 윤봉태 메인 gnb 수정 */
div.header-wrap {
  position: relative;
  z-index: 300;
  width: 100%;
  min-width: 990px;
  min-height: 109px;
  background: none;
}
div.header-wrap div.gnb-wrap {
  width: 100%;
  background-color: #fff;
}

div.header-wrap div.gnb-v2 > ul > li > a:hover,
div.header-wrap div.gnb-v2 > ul > li > a.on,
div.header-wrap div.gnb-v2 ul.left-menu > li div#eduCountry > a:hover,
div.header-wrap div.gnb-v2 ul.left-menu > li div#eduCountry > a.on {
  background: #fff;
}
div.header-wrap div.gnb-v2 div.gnb-layer-container {
  display: none;
  position: absolute;
  top: 109px;
  left: 0;
  width: 100%;
  background: #fff url('//img.godo.co.kr/godomall/header/gnb-layer-bg_new.png') repeat-x left top;
  border-bottom: 1px solid #878787;
}
div.header-wrap div.gnb-v2 a.link-all-service img {
  margin-top: 17px;
}

div.footer-wrap div.about address {
  /*font-size:12px;*/
}
div.footer-wrap div.about address span {
  /*font-size:12px;*/
}

/*20160715 아카데미 gnb 수정*/
.header-wrap .gnb-v2 .left-menu > .edu div.gnb-layer-container ul.sub-menu > li {
  width: auto;
  padding: 0 25px;
}

.gnb-lt0 {
  letter-spacing: 0;
}

/*상단 수정*/
.main_top_menu .last {
  background: none;
  padding: 0;
}
.main_top_menu .btn_make {
  position: relative;
  top: -4px;
  display: inline-block;
  width: 118px;
  height: 23px;
  line-height: 21px;
  font-size: 11px;
  color: #ffffff;
  text-align: center;
  background: #fa2828 url('//img.godo.co.kr/godomall/main/1711/ico_arrow_make.png') no-repeat 103px 8px;
  text-indent: -10px;
  border-radius: 3px;
}
.main_top_menu ul li a.benefit {
  color: #fa2828;
}

.sub_top_menu .last {
  background: none;
  padding: 0;
}
.sub_top_menu .btn_make {
  position: relative;
  top: -1px;
  display: inline-block;
  width: 118px;
  height: 23px;
  line-height: 21px;
  font-size: 11px;
  color: #ffffff;
  text-align: center;
  background: #fa2828 url('//img.godo.co.kr/godomall/main/1711/ico_arrow_make.png') no-repeat 103px 8px;
  text-indent: -10px;
  border-radius: 3px;
}
.sub_top_menu ul li a.benefit {
  color: #fa2828;
}

/*사이트맵 페이지*/
.sitemap {
  background-color: #f3f3f5;
}
.sitemap div.all-service {
  height: inherit;
  z-index: 0;
}
.sitemap div.all-service div.layer-wrap h2 {
  font-size: 26px;
  color: #222222;
  padding: 20px 0 0;
}
.sitemap div.all-service div.layer-wrap div.cnt-wrap div.right {
  width: 750px;
  height: 687px;
  border: 1px solid #d6d6d6;
  border-left: 0;
  margin: -1px 0 0 -16px;
  padding: 20px 0 0 38px;
}
.sitemap div.all-service div.layer-wrap div.cnt-wrap {
  margin: 10px 0 100px 0;
}

/* 아카데미 GNB */
/*header*/
#academy_header_wrap {
  width: 100%;
  min-width: 980px;
}
#academy_header_wrap #header {
  height: 466px;
  overflow: hidden;
}
#academy_header_wrap #header.sub1 {
  background: url('//img.godo.co.kr/godomall/header/sub/v2/bg_sub1_visual.png') no-repeat center 0;
}
#academy_header_wrap #header.sub2 {
  background: url('//img.godo.co.kr/godomall/header/sub/v2/bg_sub2_visual.png') no-repeat center 0;
}
#academy_header_wrap #header.sub3 {
  background: url('//img.godo.co.kr/godomall/header/sub/v2/bg_sub3_visual.png') no-repeat center 0;
}
#academy_header_wrap #header.sub4 {
  background: url('//img.godo.co.kr/godomall/header/sub/v2/bg_sub4_visual.png') no-repeat center 0;
}
#academy_header_wrap #header .top_wrap {
  width: 980px;
  margin: 0 auto;
  position: relative;
}
#academy_header_wrap #header h1 {
  position: absolute;
  top: 29px;
  left: 0;
}
#academy_header_wrap #header .member_gnb {
  overflow: hidden;
  position: absolute;
  top: 26px;
  right: 0;
}
#academy_header_wrap #header .member_gnb li {
  float: left;
  padding: 0 0 0 20px;
  margin: 0 0 0 20px;
  background: url('//img.godo.co.kr/godomall/header/sub/v2/icon_member_bar.png') no-repeat 0 5px;
}
#academy_header_wrap #header .member_gnb li:first-child {
  background: none;
}
#academy_header_wrap #header .member_gnb li a {
  color: #d4d4d4;
  font-size: 14px;
  font-weight: 100;
  letter-spacing: -1px;
}
#academy_header_wrap #header .member_gnb li.cart a {
  background: url('//img.godo.co.kr/godomall/header/sub/v2/icon_member_gnb_cart.png') no-repeat 0 center;
  padding: 0 0 0 25px;
}
#academy_header_wrap #header .gnb_wrap {
  position: relative;
  width: 100%;
  margin: 0 auto;
}
#academy_header_wrap #header .gnb_wrap .gnb_line {
  display: block;
  width: 100%;
  height: 1px;
  background: rgba(125, 125, 125, 0.3);
  position: absolute;
  top: 111px;
}
#academy_header_wrap #header .gnb {
  width: 980px;
  margin: 0 auto;
  position: relative;
}
#academy_header_wrap #header .gnb > ul {
  position: absolute;
  top: 78px;
  right: 0;
}
#academy_header_wrap #header .gnb > ul > li {
  float: left;
  position: relative;
}
#academy_header_wrap #header .gnb > ul > li > span {
  display: block;
}
#academy_header_wrap #header .gnb > ul > li > span > a {
  position: relative;
  display: inline-block;
  font-size: 16px;
  color: #fff;
  font-weight: 400;
  margin: 0 20px;
  padding: 0 0 16px 0;
  -webkit-transition: 0.1s;
  -moz-transition: 0.1s;
  -ms-transition: 0.1s;
  -o-transition: 0.1s;
  transition: 0.1s;
}
#academy_header_wrap #header .gnb > ul > li:last-child > span > a {
  margin-right: 0;
}
#academy_header_wrap #header .gnb > ul > li.active > span > a {
  border-bottom: 2px solid #fff;
}
#academy_header_wrap #header .gnb > ul > li > span .gnb_sub {
  display: none;
}
#academy_header_wrap #header .gnb > ul > li > span > ul {
  position: absolute;
  top: 33px;
  left: 20px;
  width: 600px;
  padding: 14px 0;
}
#academy_header_wrap #header .gnb > ul > li:nth-of-type(6) > span > ul {
  width: 323px;
  left: auto;
  right: 0;
}
#academy_header_wrap #header .gnb > ul > li > span > ul li {
  float: left;
  display: inline-block;
}
#academy_header_wrap #header .gnb > ul > li > span > ul li a {
  padding: 0 0 0 18px;
  margin: 0 0 0 16px;
  font-size: 14px;
  color: #fff;
  font-weight: 200;
  background: url('//img.godo.co.kr/godomall/header/sub/v2/bg_gnb_dot.png') no-repeat 0 center;
}
#academy_header_wrap #header .gnb > ul > li > span > ul li a:hover {
  text-decoration: underline;
}
#academy_header_wrap #header .gnb > ul > li > span > ul li:first-child a {
  padding: 0;
  margin: 0;
  background: none;
}
#academy_header_wrap #header .location_wrap {
  position: relative !important;
  width: 1200px !important;
  margin: 0 auto !important;
  height: auto !important;
  border-bottom: none !important;
}
#academy_header_wrap #header .location {
  position: absolute;
  top: 352px;
  right: 0;
  overflow: hidden;
}
#academy_header_wrap #header .location li {
  float: left;
  padding: 0 0 0 19px;
  margin: 0 0 0 14px;
  background: url('//img.godo.co.kr/godomall/header/sub/v2/icon_location_arrow_off.png') no-repeat 0 5px;
}
#academy_header_wrap #header .location li:first-child {
  margin: 0;
  padding: 0;
  background: none;
}
#academy_header_wrap #header .location li.active {
  background: url('//img.godo.co.kr/godomall/header/sub/v2/icon_location_arrow_on.png') no-repeat 0 5px;
}
#academy_header_wrap #header .location li a {
  font-size: 14px;
  color: #ddd;
  font-weight: 300;
}
#academy_header_wrap #header .location li.active a {
  font-size: 14px;
  color: #fff;
  font-weight: 400;
}
#academy_header_wrap #header .visual_title {
  margin: 219px auto 0 auto;
  text-align: center;
  color: #fff;
}
#academy_header_wrap #header .visual_title strong {
  display: block;
  font-size: 40px;
  font-weight: 500;
  padding: 0 0 18px 0;
}
#academy_header_wrap #header .visual_title span {
  font-size: 22px;
  font-weight: 300;
}

/*전체메뉴 레이어 팝업*/
.ly_allmenu_wrap {
  display: none;
  position: absolute;
  left: 50%;
  top: 0;
  width: 1060px;
  margin-left: -530px;
  background: #333;
  z-index: 450;
}
.ly_allmenu_wrap .ly_allmenu {
  position: relative;
  padding: 70px 0 0 0;
  box-sizing: border-box;
}
.ly_allmenu_wrap .ly_allmenu a {
  color: #fff;
}
.ly_allmenu_wrap .ly_allmenu .btn_home {
  padding: 0 29px 0 0;
}
.ly_allmenu_wrap .ly_allmenu .btn_ly_all_close {
  cursor: pointer;
}
.ly_allmenu_wrap .ly_allmenu .login_box {
  overflow: hidden;
  position: absolute;
  top: 70px;
  right: 0;
}
.ly_allmenu_wrap .ly_allmenu .login_box li {
  float: left;
}
.ly_allmenu_wrap .ly_allmenu .login_box li a {
  font-size: 14px;
  display: inline-block;
  width: 120px;
  padding: 11px 0;
  border: 1px solid #fff;
  text-align: center;
  margin: 0 0 0 10px;
}
.ly_allmenu_wrap .ly_allmenu .login_box li:first-child a {
  margin: 0;
}
.ly_allmenu_wrap .ly_allmenu .allmenu_gnb {
  overflow: hidden;
  margin: 148px 0 0 0;
}
.ly_allmenu_wrap .ly_allmenu .allmenu_gnb > li {
  float: left;
  width: 260px;
  height: 195px;
  margin: 0 0 133px 140px;
  background: url('//img.godo.co.kr/godomall/header/sub/v2/icon_ly_menu01.png') no-repeat right 0;
}
.ly_allmenu_wrap .ly_allmenu .allmenu_gnb > li.gnb01,
.ly_allmenu_wrap .ly_allmenu .allmenu_gnb > li.gnb04 {
  margin-left: 0;
}
.ly_allmenu_wrap .ly_allmenu .allmenu_gnb > li.gnb01 {
  background: url('//img.godo.co.kr/godomall/header/sub/v2/icon_ly_menu01.png') no-repeat right 0;
}
.ly_allmenu_wrap .ly_allmenu .allmenu_gnb > li.gnb02 {
  background: url('//img.godo.co.kr/godomall/header/sub/v2/icon_ly_menu02.png') no-repeat right 0;
}
.ly_allmenu_wrap .ly_allmenu .allmenu_gnb > li.gnb03 {
  background: url('//img.godo.co.kr/godomall/header/sub/v2/icon_ly_menu03.png') no-repeat right 0;
}
.ly_allmenu_wrap .ly_allmenu .allmenu_gnb > li.gnb04 {
  background: url('//img.godo.co.kr/godomall/header/sub/v2/icon_ly_menu04.png') no-repeat right 0;
}
.ly_allmenu_wrap .ly_allmenu .allmenu_gnb > li.gnb05 {
  background: url('//img.godo.co.kr/godomall/header/sub/v2/icon_ly_menu05.png') no-repeat right 0;
}
.ly_allmenu_wrap .ly_allmenu .allmenu_gnb > li.gnb06 {
  background: url('//img.godo.co.kr/godomall/header/sub/v2/icon_ly_menu06.png') no-repeat right 0;
}
.ly_allmenu_wrap .ly_allmenu .allmenu_gnb > li strong {
  font-size: 24px;
  color: #fff;
}
.ly_allmenu_wrap .ly_allmenu .allmenu_gnb > li > ul {
  margin: 30px 0 0 0;
}
.ly_allmenu_wrap .ly_allmenu .allmenu_gnb > li > ul > li {
  line-height: 34px;
}
.ly_allmenu_wrap .ly_allmenu .allmenu_gnb > li > ul > li a {
  display: inline-block;
  position: relative;
  font-size: 16px;
  font-weight: 200;
}
.ly_allmenu_wrap .ly_allmenu .allmenu_gnb > li > ul > li a:after {
  content: '';
  display: block;
  position: absolute;
  bottom: 3px;
  left: 0;
  height: 1px;
  width: 0;
  background: #fff;
  transition: all 0.4s;
}
.ly_allmenu_wrap .ly_allmenu .allmenu_gnb > li > ul > li a:hover:after,
.ly_allmenu_wrap .ly_allmenu .allmenu_gnb > li > ul > li a:focus:after {
  width: 100%;
}
/* //아카데미 GNB */

/* shopby main GNB */
.shopby_header.main {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-width: 980px;
  z-index: 1000;
}
.shopby_header.main .top_func_wrap {
  background: none;
  border-bottom: 1px solid rgba(223, 233, 241, 0.3);
}
.shopby_header.main .gnb_wrap {
  background: none;
}
.shopby_header.main .gnb_wrap .gnb h2 img {
  display: none;
}
.shopby_header.main .gnb_wrap .gnb h2 img.logo_wh {
  display: block;
}
.shopby_header.main .gnb_wrap .gnb ul li a {
  color: #fff;
}
.shopby_header.main .gnb_wrap .gnb ul li a.on {
  color: #fff;
}

.shopby_header.main .gnb_wrap .gnb ul li a:hover {
  color: #fff;
}

.shopby_header.main .top_func_wrap .top_func > ul > li.bar {
  background: url('//img.godo.co.kr/shopby/gnb/icon_bar2.png') no-repeat left 4px;
}
.shopby_header.main .top_func_wrap .top_func > ul > li > a {
  color: #fff;
}
.shopby_header.main .top_func_wrap .top_func > ul > li a:hover {
  color: #fff;
}
.shopby_header.main .top_func_wrap .top_func > ul > li .select_func {
  color: #fff;
  border: 1px solid rgba(255, 255, 255, 0.3);
}
.shopby_header.main .top_func_wrap .top_func > ul > li .select_func.on {
  border: 1px solid #ccd5dc;
  background-color: #fff;
}
/* .shopby_header.main .top_func_wrap .top_func > ul > li .select_func ul{border: 0;} */
.shopby_header.main .top_func_wrap .top_func > ul > li .select_func .user_id {
  color: #fff;
  background-image: url('//img.godo.co.kr/shopby/gnb/icon_arrow_white.png');
}
.shopby_header.main .top_func_wrap .top_func > ul > li .select_func.on .user_id {
  color: #444;
  background-image: url('//img.godo.co.kr/shopby/gnb/icon_arrow.png');
}
.shopby_header.main .top_func_wrap .top_func > ul > li .select_func ul li a:hover {
  color: #4769d6;
}

/* shopby GNB */
.shopby_header {
  font-family: noto;
  font-weight: 300;
  min-width: 980px;
}
.shopby_header .top_func_wrap {
  border-bottom: 1px solid #dfe9f1;
  background-color: #fff;
}
.shopby_header .top_func_wrap .top_func {
  position: relative;
  width: 980px;
  height: 60px;
  margin: 0 auto;
}
.shopby_header .top_func_wrap .top_func h1 {
  padding-top: 17px;
}
.shopby_header .top_func_wrap .top_func > ul {
  position: absolute;
  right: 0;
  top: 20px;
}
.shopby_header .top_func_wrap .top_func > ul:after {
  content: '';
  display: block;
  clear: both;
}
.shopby_header .top_func_wrap .top_func > ul > li {
  float: left;
  display: inline-block;
  font-size: 15px;
  color: #666;
}
.shopby_header .top_func_wrap .top_func > ul > li.bar {
  background: url('//img.godo.co.kr/shopby/gnb/icon_bar.png') no-repeat left 4px;
}
.shopby_header .top_func_wrap .top_func > ul > li > a {
  display: block;
  padding: 0 25px 0 25px;
}
.shopby_header .top_func_wrap .top_func > ul > li .select_func ul li a:hover {
  color: #4769d6;
}
.shopby_header .top_func_wrap .top_func > ul > li .select_func {
  position: relative;
  top: -5px;
  height: 28px;
  border: 1px solid #ccd5dc;
}
.shopby_header .top_func_wrap .top_func > ul > li .select_func .mid_bar {
  display: none;
  position: absolute;
  bottom: -1px;
  left: 10px;
  width: 104px;
  height: 1px;
  background-color: #ccd5dc;
  z-index: 1;
}
.shopby_header .top_func_wrap .top_func > ul > li .select_func.admin .mid_bar {
  bottom: -1px;
  left: 10px;
  width: 250px;
}
.shopby_header .top_func_wrap .top_func > ul > li .select_func.func {
  width: 124px;
}
.shopby_header .top_func_wrap .top_func > ul > li .select_func.admin {
  width: 270px;
  margin-left: 5px;
}
.shopby_header .top_func_wrap .top_func > ul > li .select_func .user_id {
  position: relative;
  height: 28px;
  line-height: 28px;
  font-size: 13px;
  color: #444;
  background-image: url('//img.godo.co.kr/shopby/gnb/icon_arrow.png');
  background-repeat: no-repeat;
  background-position: 107px 10px;
  padding: 0 10px 0 10px;
}
.shopby_header .top_func_wrap .top_func > ul > li .select_func.admin .user_id {
  background-position: 253px 10px;
}

.shopby_header .top_func_wrap .top_func > ul > li .select_func a {
  display: block;
  font-size: 13px;
  color: #444;
}
.shopby_header .top_func_wrap .top_func > ul > li .select_func.on .user_id {
  background-position: 107px -12px;
}
.shopby_header .top_func_wrap .top_func > ul > li .select_func.admin.on .user_id {
  background-position: 253px -12px;
}
.shopby_header .top_func_wrap .top_func > ul > li .select_func ul {
  display: none;
  position: absolute;
  top: 28px;
  left: -1px;
  width: 124px;
  font-size: 13px;
  border: 1px solid #ccd5dc;
  border-top: 0;
  background-color: #ffffff;
  z-index: 1;
  padding: 12px 0 12px 0;
  height: 122px;
}

.shopby_header .top_func_wrap .top_func > ul > li .select_func.admin ul {
  width: 270px;
  height: auto;
  overflow-y: auto;
  max-height: 180px;
}

.shopby_header .top_func_wrap .top_func > ul > li .select_func ul.on {
  display: block;
}
.shopby_header .top_func_wrap .top_func > ul > li .select_func ul li a {
  padding: 7px 10px 7px 10px;
}
/* .shopby_header .top_func_wrap .top_func > ul > li .select_func ul li{padding:3px 0 3px 0;} */
.shopby_header .top_func_wrap .top_func > ul > li .select_func ul li a:hover {
  background-color: #f2f7fb;
}
.shopby_header .top_func_wrap .top_func > ul > li a.on {
  color: #4769d6;
}
.shopby_header .top_func_wrap .top_func > ul > li.start {
  display: none;
  position: relative;
  top: -8px;
  background: none;
}
.shopby_header .top_func_wrap .top_func > ul > li.start.on {
  display: block;
}
.shopby_header .top_func_wrap .top_func > ul > li.start a {
  width: 143px;
  font-size: 14px;
  color: #fff;
  font-weight: 700;
  background: #707aec;
  border-radius: 20px;
  padding: 8px 0 9px 0;
  text-align: center;
  margin-left: 13px;
}
/* .shopby_header .top_func_wrap .top_func > ul > li.start a{width: 143px;font-size: 14px; color: #fff;font-weight: 700;background: url("//img.godo.co.kr/shopby/gnb/bg_free.png") no-repeat left top;padding: 8px 0 9px 0;text-align: center;margin-left: 13px;} */

.shopby_header .top_func_wrap .top_func > ul > li.admin {
  display: none;
  position: relative;
  top: -8px;
  background: none;
}
.shopby_header .top_func_wrap .top_func > ul > li.admin.on {
  display: block;
}
.shopby_header .top_func_wrap .top_func > ul > li.admin a {
  width: 143px;
  font-size: 14px;
  color: #fff;
  font-weight: 700;
  background: url('//img.godo.co.kr/shopby/gnb/bg_admin.png') no-repeat left top;
  padding: 8px 0 9px 0;
  text-align: center;
  margin-left: 13px;
}

.shopby_header .gnb_wrap {
  background-color: #f2f7fb;
}
.shopby_header .gnb_wrap .gnb {
  position: relative;
  width: 980px;
  height: 60px;
  margin: 0 auto;
}
.shopby_header .gnb_wrap .gnb h2 {
  padding-top: 15px;
  display: inline-block;
}
.shopby_header .gnb_wrap .gnb h2 .logo_wh {
  display: none;
}
.shopby_header .gnb_wrap .gnb ul {
  position: absolute;
  right: 0;
  top: 17px;
  overflow: hidden;
}
.shopby_header .gnb_wrap .gnb ul li {
  float: left;
  margin-left: 50px;
}
.shopby_header .gnb_wrap .gnb ul li:first-child {
  margin: 0;
}
.shopby_header .gnb_wrap .gnb ul li a {
  font-size: 16px;
  color: #333;
}
.shopby_header .gnb_wrap .gnb ul li a.on {
  color: #4769d6;
}
.shopby_header .gnb_wrap .gnb ul li a:hover {
  color: #4769d6;
}

/* 고객센터 기능팝업 190208 */
/* 추후 다시 작업 예정(김정식 대리 작업, 정헤인 사원 주석처리190604)
.menu_func_wrap{position: relative;z-index: 99999999;}
.menu_func_wrap > a{padding: 0 10px 0 0;background: url("//img.godo.co.kr/godo/customer/1902/sp_func_arrow.png") no-repeat  right 5px;}
.menu_func_wrap.on > a{background-position: right -34px;}
.menu_func_wrap .menu_func{display: none;position: absolute; top: -7px; border: 1px solid #707070; width: 82px; border-top: 0; text-align: center; background-color: #fff; left: -6px;}
.menu_func_wrap.on .menu_func{display:	block;}
.menu_func_wrap .menu_func_view{display: block;height: 28px;line-height: 26px;font-size: 12px;color: #888;background: url("//img.godo.co.kr/godo/customer/1902/sp_func_arrow.png") no-repeat 56px 10px;margin: 4px 8px 9px 8px;border-bottom: 1px solid #d7d7d7;text-indent: -5px;padding: 0 0 2px 0;}
.menu_func_wrap.on .menu_func_view{background-position: 56px -34px;}
.menu_func_wrap .menu_func a{display: block;height: 28px;line-height: 26px;font-size: 12px;color: #888;}
.menu_func_wrap .menu_func a:hover{background-color:#f6f7f9;color:#fa2828;font-weight: bold;text-decoration: none;}
.menu_func_wrap  a.menu_func_view:hover{background-color: #fff;color:#888;font-weight: normal;}

.main_header .menu_func_wrap .menu_func{top: -10px;left: -7px;letter-spacing: -0.5px;}
*/

/* 190822 */
div.footer-wrap {
  height: auto;
}
div.footer-wrap div.contents {
  height: initial;
  padding: 0 0 62px 0;
}
div.footer-wrap div.contents .design_footer {
  display: inline-block;
  font-size: 12px;
  color: #666e9b;
  padding: 18px 0 0 0;
  line-height: 1.5em;
}
div.footer-wrap div.contents div.isms {
  width: 292px;
  text-align: right;
}
div.footer-wrap div.contents div.isms .icon_reddot {
}
div.footer-wrap div.contents div.isms a:first-child {
  margin: 0;
  position: absolute;
  left: 0;
  top: 11px;
}
div.footer-wrap div.contents div.about address strong {
  display: inline-block;
  margin: 5px 0 0 0;
}
div.footer-wrap div.contents div.icon {
  margin: 20px 0 0 0;
}

/* reset */
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  font-family: 'Noto Sans KR', 'Roboto', sans-serif !important;
  color: #999;
  font-weight: 400;
  line-height: 1.3;
  height: auto;
}
input,
select,
em,
textarea,
button {
  font-style: normal;
  font-family: 'Noto Sans KR', 'Montserrat', sans-serif;
}
input,
button,
select,
textarea {
  outline: none;
  resize: none;
}
input[type='text']::-ms-clear {
  display: none;
}
input[type='password']::-ms-clear {
  display: none;
}
::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}
::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}
ol,
ul,
li {
  list-style: none;
}
address,
caption,
cite,
code,
dfn,
em,
var {
  font-style: normal;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
table {
}
a {
  text-decoration: none;
  color: #333;
  font-family: 'Noto Sans KR', sans-serif;
}
button {
  border: 0;
}
img {
  vertical-align: top;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
}

/* common style */
.hidden {
  display: none;
}
.wrapper-box {
  min-width: 980px;
  position: relative;
  padding-top: 158px;
}
body.type-pdNone {
  padding-top: 0;
}
.guide-wrap {
  width: 1200px;
  margin: 0 auto;
}
@media screen and (max-width: 750px) {
  .guide-wrap {
    width: 900px;
  }
}

.guide-line {
  width: 980px;
  margin: 0 auto;
}

/* common button */
.btn-shop {
  display: inline-block;
  width: 178px;
  height: 60px;
  line-height: 60px;
  text-align: center;
  padding: 0 30px;
  box-sizing: border-box;
  border-radius: 30px;
  background: -webkit-linear-gradient(-45deg, #e74dbd, #fe924a, #e74dbd, #fe924a);
  -moz-background: linear-gradient(-45deg, #e74dbd, #fe924a, #e74dbd, #fe924a);
  -ms-background: linear-gradient(-45deg, #e74dbd, #fe924a, #e74dbd, #fe924a);
  background: linear-gradient(-45deg, #e74dbd, #fe924a, #e74dbd, #fe924a);
  background-size: 500% auto;
  animation: btnAni 3s infinite linear;
  transition: all 0.3s ease-in-out;
}
.btn-shop:hover {
  background: linear-gradient(-45deg, #e74dbd, #e74dbd, #fe924a);
}
.btn-shop span {
  display: inline-block;
  font-weight: 700;
  font-size: 18px;
  color: #fff;
}

/* header */
.comm-header-wrap {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  box-sizing: border-box;
  z-index: 1000;
}
.comm-header-wrap a {
  font-family: 'Noto Sans KR', 'Roboto', sans-serif;
  letter-spacing: 0;
}
.comm-header-wrap .header {
  position: relative;
  transition: all 0.3s;
}
.comm-header-wrap .header.sub-header {
  border-bottom: 1px solid #e6e6e6;
  background: #fff;
}
.comm-header-wrap .header.type-shadow {
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.06);
}
.comm-header-wrap.re-fixed {
  position: fixed;
}
.comm-header-wrap.re-fixed .header {
  top: -36px;
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.06);
  background: #fff;
}
.comm-header-wrap.re-fixed .header.sub-header {
  border-bottom: 1px solid #fff;
}
.comm-header-wrap.re-fixed.up .header {
  top: 0;
}
.comm-header-wrap.active {
  background: #fff;
}
.comm-utill-wrap {
  position: relative;
  transition: all 0.2s;
}
.comm-utill-wrap.up {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  background: #fff;
}
.comm-utill-wrap.up + .comm-gnb-wrap.re-fixed {
  top: 30px;
}
.comm-utill-wrap ul {
  text-align: left;
  width: 980px;
  margin: 0 auto;
  padding-top: 16px;
}
.comm-utill-wrap ul li {
  display: inline-block;
  position: relative;
}
.comm-utill-wrap ul li:before {
  display: inline-block;
  content: '';
  width: 4px;
  height: 4px;
  margin: 0 10px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.1);
  vertical-align: middle;
}
.comm-utill-wrap ul li:first-child:before {
  display: none;
}
.comm-utill-wrap ul li a {
  display: inline-block;
  font-weight: 400;
  line-height: 20px;
  font-size: 14px;
  color: #111;
  vertical-align: middle;
}
.comm-gnb-wrap {
  position: relative;
  padding: 10px 0 0;
  transition: all 0.2s;
}
.comm-gnb-wrap.re-fixed {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  background: #fff;
}
.comm-gnb-wrap .comm-gnb-box {
  width: 980px;
  margin: 0 auto;
}
.comm-gnb-wrap .comm-gnb-box:after {
  display: block;
  content: '';
  clear: both;
}
.comm-gnb-wrap h1 {
  display: inline-block;
  float: left;
  padding: 10px 0;
}
.comm-gnb-wrap h1 a img {
  width: auto;
  height: 32px;
  vertical-align: middle;
}
.comm-gnb-wrap h1 .txt-sub {
  display: inline-block;
  margin-left: 10px;
  padding-bottom: 4px;
  font-weight: 500;
  font-size: 16px;
  color: #f7444e;
  vertical-align: middle;
}
.comm-gnb-wrap .comm-gnb {
  float: left;
  box-sizing: border-box;
  margin-left: 60px;
}
.comm-gnb-wrap .comm-gnb > ul {
}
.comm-gnb-wrap .comm-gnb > .depth-1:after {
  display: block;
  content: '';
  clear: both;
}
.comm-gnb-wrap .comm-gnb > .depth-1 > li:nth-of-type(1) {
  margin-left: 0;
}
.comm-gnb-wrap .comm-gnb > .depth-1 > li {
  float: left;
  margin-left: 30px;
}
.comm-gnb-wrap .comm-gnb > .depth-1 > li > a {
  font-family: 'Noto Sans KR';
  position: relative;
  display: block;
  line-height: 50px;
  box-sizing: border-box;
  padding-bottom: 10px;
  font-weight: 500;
  font-size: 18px;
  color: #121212;
}
.comm-gnb-wrap .comm-gnb > .depth-1 > li > a:hover:before {
  display: block;
  content: '';
  width: 100%;
  height: 6px;
  background: #f7444e;
  position: absolute;
  left: 0;
  bottom: -1px;
}
.comm-gnb-wrap .comm-gnb > .depth-1 > li > .depth-2-wrap {
  display: none;
  width: 100%;
  position: absolute;
  top: 70px;
  left: 0;
  border-top: 1px solid #ccc;
  background: #fff;
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.06);
  z-index: 10;
}
.comm-gnb-wrap .comm-gnb > .depth-1 > li > .depth-2-wrap .depth-2 {
  width: 1280px;
  margin: 0 auto;
}
.comm-gnb-wrap .comm-gnb > .depth-1 > li > .depth-2-wrap .depth-2:after {
  display: block;
  content: '';
  clear: both;
}
.comm-gnb-wrap .comm-gnb > .depth-1 > li > .depth-2-wrap .gnb-banner {
  float: left;
  margin: 0 20px 0 40px;
}
.comm-gnb-wrap .comm-gnb > .depth-1 > li > .depth-2-wrap .gnb-banner img {
  width: 384px;
  height: 260px;
}
.comm-gnb-wrap .comm-gnb > .depth-1 > li > .depth-2-wrap .depth-2 > ul:nth-of-type(1) {
  float: left;
}
.comm-gnb-wrap .comm-gnb > .depth-1 > li > .depth-2-wrap .depth-2 > ul {
  float: left;
  margin: 0 40px;
  padding: 31px 0 30px;
}
.comm-gnb-wrap .comm-gnb > .depth-1 > li > .depth-2-wrap .depth-2 > ul > li {
  line-height: 22px;
}
.comm-gnb-wrap .comm-gnb > .depth-1 > li > .depth-2-wrap .depth-2 > ul > li a {
  display: inline-block;
  margin-bottom: 10px;
  font-weight: 300;
  font-size: 15px;
  color: #111;
}
.comm-gnb-wrap .comm-gnb > .depth-1 > li > .depth-2-wrap .depth-2 > ul > li a:hover {
  text-decoration: underline;
}
.comm-gnb-wrap .comm-gnb > .depth-1 > li > .depth-2-wrap .depth-2 > ul > li a.tit {
  margin-bottom: 17px;
  font-weight: 500;
  font-size: 16px;
}
.comm-gnb-wrap .comm-gnb > .depth-1 > li > .depth-2-wrap .depth-2 > ul > li a.tit:hover,
.comm-gnb-wrap .comm-gnb > .depth-1 > li > .depth-2-wrap .depth-2 > ul > li a.tit.on {
  color: #f7444e;
}
.comm-gnb-wrap .comm-gnb > .depth-1 > li > .depth-2-wrap .depth-2 > ul > li a.new-window {
  padding-right: 16px;
  background: url(//img.godo.co.kr/godomall_v2/main/icon_new_window.png) no-repeat 100% 50%;
}
.depth-2-wrap .depth-2 .depth-2-contents {
  clear: both;
  margin: 0 40px 40px;
  display: block;
  overflow: hidden;
}
.depth-2-wrap .depth-2 .depth-2-contents .cont-left {
  float: left;
  width: 690px;
  box-sizing: border-box;
  margin-right: 24px;
  padding: 25px 30px;
  background: #f7f7f7;
}
.depth-2-wrap .depth-2 .depth-2-contents .cont-left .key-wrap {
  margin-top: 11px;
}
.depth-2-wrap .depth-2 .depth-2-contents .cont-left .key-wrap a {
  display: inline-block;
  text-align: center;
  height: 24px;
  line-height: 22px;
  margin-right: 4px;
  padding: 0 14px;
  box-sizing: border-box;
  border-radius: 12px;
  border: 1px solid #bfcbeb;
  font-weight: 400;
  font-size: 12px;
  color: #2d68d2;
  letter-spacing: -0.24px;
}
.depth-2-wrap .depth-2 .depth-2-contents .cont-left .key-wrap a:hover {
  border: 1px solid #272b3a;
  color: #fff;
  background: #272b3a;
}
.depth-2-wrap .depth-2 .depth-2-contents .cont-left .tit-1 {
  font-weight: 400;
  font-size: 14px;
  color: #666;
  letter-spacing: -0.3px;
}
.depth-2-wrap .depth-2 .depth-2-contents .cont-right {
  float: left;
  width: 486px;
  box-sizing: border-box;
  padding: 25px 30px 16px;
  background: #f7f7f7;
}
.depth-2-wrap .depth-2 .depth-2-contents .cont-right .cs-tell {
  float: left;
}
.depth-2-wrap .depth-2 .depth-2-contents .cont-right .cs-tell .txt-1 {
  display: block;
  font-weight: 400;
  font-size: 14px;
  color: #666;
  letter-spacing: -0.3px;
}
.depth-2-wrap .depth-2 .depth-2-contents .cont-right .cs-tell .txt-2 {
  display: inline-block;
  margin-top: 10px;
  font-family: Montserrat;
  font-weight: 700;
  font-size: 30px;
  color: #111;
}
.depth-2-wrap .depth-2 .depth-2-contents .cont-right .cs-info {
  float: left;
  margin-top: 10px;
}
.depth-2-wrap .depth-2 .depth-2-contents .cont-right .cs-info .txt-1 {
  display: block;
  margin: 13px 0 0 24px;
  font-weight: 300;
  font-size: 13px;
  color: #111;
  letter-spacing: -0.3px;
}
.depth-2-wrap .depth-2 .depth-2-contents .cont-right .cs-info .txt-1 em {
  font-family: Montserrat;
  font-weight: 500;
  font-size: 14px;
  color: #111;
}
.depth-2-wrap .depth-2 .depth-2-contents .cont-right .cs-info .txt-2 {
  display: inline-block;
  margin-left: 24px;
  font-weight: 300;
  letter-spacing: -0.3px;
}
.comm-gnb-wrap .gnb-button-wrap {
  float: right;
}
.comm-gnb-wrap .gnb-button-wrap > a {
  display: inline-block;
  width: 125px;
  height: 50px;
  line-height: 50px;
  margin-left: 5px;
  border-radius: 25px;
  vertical-align: middle;
}
.comm-gnb-wrap .gnb-button-wrap .txt-name {
  line-height: 48px;
  padding-right: 20px;
  font-weight: 700;
  font-size: 16px;
  color: #111;
  background: url(//img.godo.co.kr/godomall_v2/main/icon_lc_down.png) no-repeat 100% 50%;
  background-size: auto 8px;
  vertical-align: middle;
}
.comm-gnb-wrap .gnb-button-wrap .btn-target {
}
.comm-gnb-wrap .gnb-button-wrap .btn-target.on .txt-name {
  background: url(//img.godo.co.kr/godomall_v2/main/icon_lc_up.png) no-repeat 100% 50%;
  background-size: auto 8px;
}
.comm-gnb-wrap .gnb-button-wrap a.btn-gnb-login {
  text-align: center;
  font-weight: 700;
  font-size: 16px;
  color: #d6dfe1;
  background: #272b3a;
}
.comm-gnb-wrap .gnb-button-wrap a.btn-gnb-free {
  text-align: center;
  font-weight: 700;
  font-size: 16px;
  color: #fff;
  border-radius: 30px;
  background: -webkit-linear-gradient(-45deg, #6d1bdf 0%, #ff2828 50%, #6d1bdf 100%);
  -moz-background: linear-gradient(-45deg, #6d1bdf 0%, #ff2828 50%, #6d1bdf 100%);
  -ms-background: linear-gradient(-45deg, #6d1bdf 0%, #ff2828 50%, #6d1bdf 100%);
  background: linear-gradient(-45deg, #6d1bdf 0%, #ff2828 50%, #6d1bdf 100%);
}
.comm-gnb-wrap .gnb-button-wrap a.btn-gnb-free {
  background-size: 500% auto;
  animation: btnAni 3s infinite linear;
}

body.type-ie-9 .btn-shop {
  background: url(https://img.godo.co.kr/godomall_v2/type_ie/bg_ie9_gradient.png) no-repeat 0 0;
  background-size: 100%;
}
body.type-ie-9 .comm-gnb-wrap .gnb-button-wrap a.btn-gnb-free {
  background: url(https://img.godo.co.kr/godomall_v2/type_ie/bg_ie9_gradient.png) no-repeat 0 0;
  background-size: 100%;
}

@-webkit-keyframes btnAni {
  0% {
    background-position: 100% 0%;
  }
  50% {
    background-position: 0% 50%;
  }
}
@-moz-keyframes btnAni {
  0% {
    background-position: 100% 0%;
  }
  50% {
    background-position: 0% 50%;
  }
}
@-ms-keyframes btnAni {
  0% {
    background-position: 100% 0%;
  }
  50% {
    background-position: 0% 50%;
  }
}
@keyframes btnAni {
  0% {
    background-position: 100% 0%;
  }
  50% {
    background-position: 0% 50%;
  }
}
.comm-gnb-wrap .gnb-button-wrap {
  position: relative;
}
.comm-gnb-wrap .gnb-button-wrap .btn-target {
  display: inline-block;
  margin-right: 15px;
}
.comm-gnb-wrap .gnb-button-wrap .btn-target.on .login-member {
  display: block;
}
.comm-gnb-wrap .gnb-button-wrap .btn-target .login-member {
  display: none;
  position: absolute;
  top: 70px;
  right: 0;
  width: 320px;
  z-index: 10;
}
.comm-gnb-wrap .gnb-button-wrap .btn-target .login-member .btn-logOut {
  display: block;
  height: 44px;
  line-height: 44px;
  margin-top: 20px;
  text-align: center;
  font-weight: 700;
  font-size: 15px;
  color: #494949;
  border: 1px solid #ccc;
  border-radius: 3px;
  background: #fff;
}
.dd-sel-wrap {
  border-radius: 4px;
  box-shadow: 2px 2px 5px rgba(51, 51, 51, 0.16);
}
.dd-sel-wrap .dd-sel-box {
  margin: 0;
}
.dd-input-sel {
  position: relative;
  height: 62px;
  padding: 0 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background: #fff;
}
.dd-input-sel.on {
  border-top: 1px solid #121212;
  border-left: 1px solid #121212;
  border-right: 1px solid #121212;
  border-bottom: 1px solid #ccc;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.dd-input-sel .select {
  display: block;
  position: relative;
  padding: 30px 0 12px;
  height: 20px;
  font-size: 15px;
  z-index: 10;
}
.dd-input-sel .select:after {
  display: block;
  content: '';
  width: 10px;
  height: 8px;
  position: absolute;
  top: 30px;
  right: 0;
  background: url(//img.godo.co.kr/godomall_v2/main/icon_lc_down.png) no-repeat 0 0;
  background-size: auto 8px;
}
.dd-input-sel .select span {
  display: inline-block;
  position: absolute;
  top: 10px;
  left: 0;
  color: #999;
  padding: 0;
  z-index: 1;
  transition-timing-function: ease-in;
  transition-duration: 0.125s;
}
.dd-input-sel .form-label {
  position: absolute;
  top: 0;
  left: 20px;
  right: 20px;
  width: auto;
  height: 20px;
  padding: 30px 0 12px;
  font-size: 15px;
  color: #121212;
}
.dd-input-sel .dd-list-wrap {
  position: relative;
  top: 1px;
  left: -21px;
  display: none;
  width: 318px;
  height: 210px;
  overflow: auto;
  border: 1px solid #121212;
  border-top: 0;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  background: #fff;
  z-index: 10;
}
.dd-input-sel .dd-list-wrap .dd-list {
  display: none;
}
/* 샵바이프로 오픈때 주석해제 */
/* .dd-input-sel .dd-list-wrap .dd-list > ul{border-top:1px solid #ccc;}
.dd-input-sel .dd-list-wrap .dd-list > ul:first-child{border-top:none;} */

/* 샵바이프로 오픈때 삭제 */
.dd-input-sel .dd-list-wrap .dd-list > ul li {
  border-top: 1px solid #e5e5e5;
}
.dd-input-sel .dd-list-wrap .dd-list > ul:first-child > li:first-child {
  border-top: none;
}
/* //샵바이프로 오픈때 삭제 */

.dd-input-sel .dd-list-wrap .dd-list > ul li {
  min-height: 60px;
}
.dd-input-sel .dd-list-wrap .dd-list > ul li a {
  position: relative;
  display: block;
  width: 100%;
  padding: 15px 0 14px;
}
.dd-input-sel .dd-list-wrap .dd-list > ul li a:hover {
  background: #f7f7f7;
}
.dd-input-sel .dd-list-wrap .dd-list > ul li a span {
  display: block;
  padding: 0 13px;
  margin-top: 6px;
  font-size: 15px;
  color: #111;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.dd-input-sel .dd-list-wrap .dd-list > ul li a span em {
  display: inline-block;
  width: 32px;
  height: 22px;
  line-height: 22px;
  margin-right: 10px;
  text-align: center;
  border-radius: 4px;
  font-size: 13px;
  color: #fff;
  background: #272a3b;
}
.dd-input-sel .dd-list-wrap .dd-list > ul li a .txt-sol {
  margin: 0;
  font-size: 12px;
  color: #4da8af;
}
.dd-input-sel .dd-list-wrap .dd-list > ul li a.sel span {
  color: #121212;
}
.dd-input-sel.on .dd-list-wrap {
  display: block;
}
.dd-input-sel.on .select:after {
  display: block;
  content: '';
  width: 10px;
  height: 8px;
  background: url(//img.godo.co.kr/godomall_v2/main/icon_lc_up.png) no-repeat 0 0;
  background-size: auto 8px;
}
.dd-input-sel.on .dd-list-wrap .dd-list {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: auto;
}
.select-admin-wrap {
  margin-top: 8px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 3px;
  box-shadow: 2px 2px 5px rgba(51, 51, 51, 0.16);
  background: #fff;
}
.select-admin-wrap .admin-list li {
  margin-bottom: 15px;
}
.select-admin-wrap .admin-list li a {
  display: block;
  height: 41px;
  line-height: 39px;
  padding-left: 51px;
  font-size: 15px;
}
.select-admin-wrap .admin-list li .btn-my-1 {
  background: url(https://img.godo.co.kr/godomall_v2/common/icon_mygodo_1.png) no-repeat 0 0;
  background-size: auto 40px;
}
.select-admin-wrap .admin-list li .btn-my-2 {
  background: url(https://img.godo.co.kr/godomall_v2/common/icon_mygodo_2.png) no-repeat 0 0;
  background-size: auto 40px;
}
.select-admin-wrap .admin-list li .btn-my-3 {
  background: url(https://img.godo.co.kr/godomall_v2/common/icon_mygodo_3.png) no-repeat 0 0;
  background-size: auto 40px;
}
.select-admin-wrap .admin-list li .btn-my-4 {
  background: url(https://img.godo.co.kr/godomall_v2/common/icon_mygodo_4.png) no-repeat 0 0;
  background-size: auto 40px;
}
.btn-shopping-wrap .btn-shopping {
  display: block;
  position: relative;
  padding: 10px 30px;
  border-radius: 3px;
  background: #f7444e;
}
.btn-shopping-wrap .btn-shopping:after {
  display: block;
  content: '';
  position: absolute;
  top: 28px;
  right: 30px;
  width: 9px;
  height: 15px;
  background: url(//img.godo.co.kr/godomall_v2/common/icon_arrow_3.png) no-repeat 0 0;
}
.btn-shopping-wrap .btn-shopping strong {
  display: block;
  line-height: 25px;
  font-weight: 700;
  font-size: 15px;
  color: #fff;
}
.btn-shopping-wrap .btn-shopping span {
  display: block;
  line-height: 25px;
  font-weight: 300;
  font-size: 14px;
  color: #fff;
}

/* sub header */
.comm-sub-location {
  border-top: 1px solid #e6e6e6;
}
.comm-sub-location .location-box {
  width: 980px;
  margin: 0 auto;
}
.comm-sub-location .location-box:after {
  display: block;
  content: '';
  clear: both;
}
.comm-sub-location .location-box .lc-home {
  display: block;
  float: left;
  width: 50px;
  height: 50px;
  background: #f7f7f7 url(//img.godo.co.kr/godomall_v2/main/icon_home.png) no-repeat 50% 50%;
  font-size: 0;
  background-size: 14px 13px;
}
.comm-sub-location .location-box .lc-text {
  display: block;
  float: left;
  line-height: 50px;
  padding: 0 20px;
}
.comm-sub-location .location-box .lc-text a {
  display: block;
  font-weight: 400;
  font-size: 15px;
  color: #737373;
}
.comm-sub-location .location-box > div:after {
  display: block;
  content: '';
  width: 1px;
  height: 20px;
  position: absolute;
  top: 15px;
  right: 0;
  background: #e6e6e6;
}
.comm-sub-location .location-box .lc-depth-2 {
  position: relative;
  display: block;
  float: left;
}
.comm-sub-location .location-box .lc-depth-2:before {
  display: block;
  content: '';
  width: 1px;
  height: 20px;
  position: absolute;
  top: 15px;
  left: 0;
  background: #e6e6e6;
}
.comm-sub-location .location-box .lc-depth-2:after,
.comm-sub-location .location-box .lc-depth-3:after,
.comm-sub-location .location-box .lc-depth-4:after {
  display: block;
  content: '';
  width: 1px;
  height: 20px;
  position: absolute;
  top: 15px;
  right: 0;
  background: #e6e6e6;
}
.comm-sub-location .location-box .lc-depth-2 > a {
  min-width: 154px;
}
.comm-sub-location .location-box .lc-depth-2 > a.none {
  min-width: auto;
}
.comm-sub-location .location-box .lc-depth-3 {
  position: relative;
  display: block;
  float: left;
}
.comm-sub-location .location-box .lc-depth-3 > a {
  min-width: 186px;
}
.comm-sub-location .location-box .lc-depth-3 > a.none {
  min-width: auto;
}
.comm-sub-location .location-box .lc-depth-4 {
  position: relative;
  display: block;
  float: left;
}
.comm-sub-location .location-box .lc-depth-4:after {
  display: none;
}
.comm-sub-location .location-box .lc-depth-4.line:before {
  display: block;
  content: '';
  width: 1px;
  height: 20px;
  position: absolute;
  top: 15px;
  left: 0;
  background: #e6e6e6;
}
.comm-sub-location .location-box > div > a {
  position: relative;
  height: 50px;
  padding: 0 20px;
  display: block;
  line-height: 50px;
  font-size: 15px;
  color: #333;
}
.comm-sub-location .location-box > div > a:after {
  position: absolute;
  right: 20px;
  top: 21px;
  display: block;
  content: '';
  width: 10px;
  height: 8px;
  background: url(//img.godo.co.kr/godomall_v2/main/icon_lc_down.png) no-repeat 0 0;
  background-size: 10px 8px;
}
.comm-sub-location .location-box > div > a.none:after {
  display: none;
}
.comm-sub-location .location-box > div .scroll-sel-list {
  display: none;
}
.comm-sub-location .location-box > div.on > a:after {
  background: url(//img.godo.co.kr/godomall_v2/main/icon_lc_up.png) no-repeat 0 0;
  background-size: 10px 8px;
}
.comm-sub-location .location-box > div.on .btn-sel + .scroll-sel-list {
  display: block;
}
.comm-sub-location .location-box > div.on .btn-sel + .scroll-sel-list .sel-list {
  display: block;
  position: absolute;
  top: 54px;
  left: 0;
  width: 100%;
  border: 1px solid #ccc;
  background: #fff;
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.06);
}
.comm-sub-location .location-box > div.on .btn-sel + .scroll-sel-list .sel-list li:first-child a {
  border-top: 0;
}
.comm-sub-location .location-box > div.on .btn-sel + .scroll-sel-list .sel-list li a {
  display: block;
  padding: 12px 20px 13px;
  border-top: 1px solid #e6e6e6;
  font-weight: 300;
  font-size: 15px;
  color: #111;
}
.comm-sub-location .location-box > div.on .btn-sel + .scroll-sel-list .sel-list li a:hover {
  background: #f7f7f7;
  color: #111;
}
.comm-sub-location .location-box > div.on .btn-sel + .scroll-sel-list .sel-list li a span {
  display: block;
}
.comm-sub-location .location-box > div.on .btn-sel + .scroll-sel-list .sel-list li a.sel {
  color: #737373;
}
.comm-sub-location .location-box > div.on .btn-sel + .scroll-sel-list .sel-list li a.sel span {
  background: url(//img.godo.co.kr/godomall_v2/main/icon_lc_sel.png) no-repeat 100% 4px;
  background-size: 12px 9px;
}

/* footer */
div.footer-wrap {
  border-top: 0;
}
div.footer-wrap * {
  font-family: 'Noto Sans KR', 'Roboto', sans-serif;
}
.footer-wrap {
  background: #333;
}
.footer-wrap .footer {
  width: 980px;
  margin: 0 auto;
  padding: 30px 0 58px;
  text-align: left;
}
.footer-wrap .footer:after {
  display: block;
  content: '';
  clear: both;
}
.footer-wrap .footer .left-wrap {
  float: left;
}
.footer-wrap .ft-info-list:after {
  display: block;
  content: '';
  clear: both;
}
.footer-wrap .ft-info-list li {
  float: left;
  margin-left: 30px;
  margin-top: 0;
  padding: 0;
  background: none;
}
.footer-wrap .ft-info-list li:first-child {
  margin-left: 0;
}
.footer-wrap .ft-info-list li a {
  display: block;
  font-weight: 400;
  font-size: 16px;
  color: #fff;
}
.footer-wrap .txt-godo {
  display: block;
  margin-top: 34px;
  font-weight: 500;
  font-size: 13px;
  color: #fff;
  letter-spacing: -0.03em;
}
.footer-wrap .ft-about {
  margin-top: 6px;
}
.footer-wrap .ft-about address {
  line-height: 29px;
  font-weight: 300;
  font-size: 13px;
  color: #999;
  letter-spacing: -0.03em;
  vertical-align: middle;
}
.footer-wrap .ft-about address span {
}
.footer-wrap .ft-about address span:before {
  content: '';
  display: inline-block;
  width: 1px;
  height: 8px;
  margin: -4px 15px 0 16px;
  background: #979797;
  vertical-align: middle;
}
.footer-wrap .ft-about address a {
  color: #999;
  text-decoration: underline;
}
.footer-wrap .ft-about address .txt-fontType {
  font-family: 'Montserrat';
  font-weight: 300;
}
.footer-wrap .footer .right-wrap {
  float: right;
}
.footer-wrap .footer .right-wrap:after {
  display: block;
  content: '';
  clear: both;
}
.footer-wrap .footer .right-wrap .inquiry-box {
  float: left;
  margin-top: 58px;
}
.footer-wrap .footer .right-wrap .inquiry-box em {
  display: block;
  font-weight: 600;
  font-size: 13px;
  color: #999;
  letter-spacing: -0.03em;
}
.footer-wrap .footer .right-wrap .inquiry-box > span {
  display: inline-block;
  margin-top: 10px;
  font-family: 'Montserrat';
  font-weight: 600;
  font-size: 30px;
  color: #fff;
  letter-spacing: -0.03em;
}
.footer-wrap .footer .right-wrap .inquiry-box p {
  margin-top: 10px;
  font-weight: 300;
  font-size: 13px;
  color: #fff;
  letter-spacing: -0.03em;
}
.footer-wrap .footer .right-wrap .inquiry-box p span {
  font-family: 'Montserrat';
  font-weight: 300;
}
.footer-wrap .footer .right-wrap .info-box {
  float: left;
  margin-left: 30px;
}
.footer-wrap .footer .right-wrap .info-box .sns-wrap a {
  display: inline-block;
  margin-left: 15px;
}
.footer-wrap .footer .right-wrap .info-box .sns-wrap a:first-child {
  margin-left: 0;
  width: 52px;
}
.footer-wrap .footer .right-wrap .info-box .sns-wrap a:nth-child(2) {
  width: 24px;
}
.footer-wrap .footer .right-wrap .info-box .sns-wrap a:last-child {
  width: 24px;
}
.footer-wrap .footer .right-wrap .info-box .sns-wrap a img {
  width: 100%;
}
.footer-wrap .family-site {
  margin-top: 34px;
}
.footer-wrap .family-site .form-input-sel {
  position: relative;
  height: 54px;
  padding: 0 15px;
  border: 1px solid rgba(255, 255, 255, 0.24);
  border-radius: 4px;
  background: #333;
  width: 232px;
  box-sizing: border-box;
}
.footer-wrap .family-site .form-input-sel .fm-selectBox {
  display: block;
  position: relative;
  padding: 15px 0 18px;
  height: 20px;
  z-index: 10;
  font-size: 15px;
  color: #fff;
}
.footer-wrap .family-site .form-input-sel .fm-selectBox:after {
  display: block;
  content: '';
  width: 10px;
  height: 8px;
  position: absolute;
  top: 23px;
  right: 0;
  background: url(//img.godo.co.kr/godomall_v2/main/icon_select_down.png) no-repeat 0 0;
  background-size: 10px;
}
.footer-wrap .family-site .form-input-sel .fm-selectBox.on span {
  font-size: 13px;
  font-weight: 400;
  top: 14px;
  color: #999;
}
.footer-wrap .family-site .form-input-sel .fm-selectBox span {
  position: inherit;
  top: inherit;
  left: inherit;
  transition: none;
  color: #fff;
}
.footer-wrap .family-site .form-input-sel .scroll-sel-list {
  position: absolute;
  top: 53px;
  left: -1px;
  display: none;
  width: 100%;
  height: 162px;
  border: 1px solid #fff;
  border-top: 0;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  background: #292929;
  z-index: 10;
}
.footer-wrap .family-site .form-input-sel .scroll-sel-list .sel-list {
  max-height: 300px;
  overflow-y: auto;
}
.footer-wrap .family-site .form-input-sel .sel-list {
  display: none;
}
.footer-wrap .family-site .form-input-sel .sel-list li {
  min-height: 30px;
  margin: 0;
  padding: 0;
  background: none;
}
.footer-wrap .family-site .form-input-sel .sel-list li a {
  position: relative;
  display: block;
  width: 100%;
  min-height: 30px;
}
.footer-wrap .family-site .form-input-sel .sel-list li a:hover {
  background: #4d4d4d;
}
.footer-wrap .family-site .form-input-sel .sel-list li a span {
  display: block;
  line-height: 15px;
  padding: 19px 15px 20px;
  font-size: 16px;
  color: #999;
}
.footer-wrap .family-site .form-input-sel .sel-list li a.sel:after {
  display: block;
  content: '';
  position: absolute;
  top: 40%;
  right: 30px;
  width: 14px;
  height: 10px;
  background: url() no-repeat 0 0;
}
.footer-wrap .family-site .form-input-sel .sel-list li a.sel span {
  color: #fff;
}
.footer-wrap .family-site .form-input-sel.on {
  border: 1px solid #fff;
  border-bottom: 1px solid #646464;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  background: #292929;
}
.footer-wrap .family-site .form-input-sel.on .fm-selectBox:after {
  display: block;
  content: '';
  width: 10px;
  height: 8px;
  position: absolute;
  top: 23px;
  right: 0;
  background: url(//img.godo.co.kr/godomall_v2/main/icon_select_up.png) no-repeat 0 0;
  background-size: 10px;
}
.footer-wrap .family-site .form-input-sel.on .scroll-sel-list {
  display: block;
}
.footer-wrap .family-site .form-input-sel.on .scroll-sel-list .sel-list {
  display: block;
  position: absolute;
  bottom: 0;
  top: 0;
  right: 0;
  width: 100%;
}
.footer-wrap .ft-award {
  margin-top: 16px;
}
.footer-wrap .ft-award:after {
  display: block;
  content: '';
  clear: both;
}
.footer-wrap .ft-award a {
  display: block;
  margin: 4px 0;
}
.footer-wrap .ft-award > a {
  float: left;
  width: 111px;
}
.footer-wrap .ft-award > a img {
  width: 100%;
}
.footer-wrap .ft-award > div {
  width: 117px;
  float: left;
  margin-left: 4px;
}
.footer-wrap .ft-award > div img {
  width: 100%;
}

/* common drop down select */
.comm-selectBox-wrap {
  position: relative;
  display: inline-block;
  min-width: 100px;
  border-radius: 3px;
  border: 1px solid #ccc;
  box-sizing: border-box;
  vertical-align: middle;
  background: #fff;
}
.comm-selectBox-wrap.disabled {
  border: 1px solid #ccc;
  background: #f2f2f2;
}
.comm-selectBox-wrap.disabled > a {
  color: #ccc;
}
.comm-selectBox-wrap.disabled > a:after {
  background: url(//img.godo.co.kr/godomall_v2/main/icon_lc_down_disabled.png) no-repeat 0 0;
  background-size: 10px;
}
.comm-selectBox-wrap > a {
  position: relative;
  height: 44px;
  padding: 0 15px;
  display: block;
  line-height: 44px;
  font-size: 15px;
}
.comm-selectBox-wrap > a:after {
  position: absolute;
  right: 15px;
  top: 18px;
  display: block;
  content: '';
  width: 10px;
  height: 8px;
  background: url(//img.godo.co.kr/godomall_v2/main/icon_lc_down.png) no-repeat 0 0;
  background-size: 10px;
}
.comm-selectBox-wrap .comm-select-list {
  display: none;
}
.comm-selectBox-wrap.on > a:after {
  background: url(//img.godo.co.kr/godomall_v2/main/icon_lc_up.png) no-repeat 0 0;
  background-size: 10px;
}
.comm-selectBox-wrap.on {
  border: 1px solid #111;
}
.comm-selectBox-wrap.on .comm-select-list {
  display: block;
  position: absolute;
  top: 43px;
  left: -1px;
  width: 100%;
  border: 1px solid #111;
  border-top: 1px solid #ccc;
  background: #fff;
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.06);
  z-index: 10;
}
.comm-selectBox-wrap.on .comm-select-list ul {
  max-height: 184px;
  overflow-y: auto;
}
.comm-selectBox-wrap.on .comm-select-list .select-list li:first-child a {
  border-top: 0;
}
.comm-selectBox-wrap.on .comm-select-list .select-list li a {
  display: block;
  padding: 13px 14px 14px;
  font-weight: 300;
  font-size: 15px;
  color: #111;
}
.comm-selectBox-wrap.on .comm-select-list .select-list li a:hover {
  background: #f7f7f7;
  color: #111;
}
.comm-selectBox-wrap.on .comm-select-list .select-list li a span {
  display: block;
  color: #999;
}
.comm-selectBox-wrap.on .comm-select-list .select-list li a.sel {
}
.comm-selectBox-wrap.on .comm-select-list .select-list li a.sel span {
  background: url(//img.godo.co.kr/godomall_v2/customer/icon_lc_sel_red.png) no-repeat 100% 4px;
  background-size: 12px 9px;
  color: #111;
}

/* input search */
.comm-input-wrap {
  position: relative;
  display: inline-block;
  min-width: 100px;
  margin-left: 2px;
  padding: 11px 50px 11px 15px;
  border-radius: 3px;
  border: 1px solid #ccc;
  box-sizing: border-box;
  vertical-align: middle;
  background: #fff;
}
.comm-input-wrap.type2 {
  position: relative;
  display: inline-block;
  min-width: 100px;
  margin-left: 2px;
  padding: 11px 15px 11px 15px;
  border-radius: 3px;
  border: 1px solid #ccc;
  box-sizing: border-box;
  vertical-align: middle;
}
.comm-input-wrap input {
  width: 100%;
  border: 0;
  font-size: 15px;
  color: #111;
}
.comm-input-wrap input:-moz-placeholder {
  color: #ccc;
}
.comm-input-wrap input:-webkit-input-placeholder {
  color: #ccc;
}
.comm-input-wrap input:-ms-input-placeholder {
  color: #ccc;
}
.comm-input-wrap .btn-srch {
  display: inline-block;
  position: absolute;
  top: 0;
  right: 0;
  width: 19px;
  height: 19px;
  padding: 12px 10px 14px;
  background: url(//img.godo.co.kr/godomall_v2/customer/icon_srch.png) no-repeat 50% 50%;
  background-size: 19px 19px;
}

/* textarea */
.comm-textarea-wrap {
  border: 1px solid #ccc;
  background: #fff;
}
.comm-textarea-wrap textarea {
  width: 100%;
  padding: 15px;
  box-sizing: border-box;
  font-size: 15px;
  color: #111;
}
.comm-textarea-wrap textarea::-webkit-input-placeholder {
  color: #ccc;
}
.comm-textarea-wrap textarea:-ms-input-placeholder {
  color: #ccc;
}
.comm-textarea-wrap textarea::-ms-input-placeholder {
  color: #ccc;
}

/* file */
.comm-file-wrap label {
  display: inline-block;
  min-width: 100px;
  height: 46px;
  line-height: 44px;
  box-sizing: border-box;
  padding: 0 22px;
  font-weight: 700;
  font-size: 15px;
  border: 1px solid #dbdde2;
  border-radius: 4px;
  background: #6a7185;
  color: #454545;
  cursor: pointer;
}
.comm-file-wrap label {
  border: 1px solid #dbdde2;
  color: #3c404d;
  background: #fff;
  vertical-align: middle;
}
.comm-file-wrap input[type='file'] {
  position: absolute;
  visibility: hidden;
}
.comm-file-wrap span {
  display: inline-block;
  margin-left: 10px;
  font-weight: 300;
  font-size: 15px;
  color: #454545;
  letter-spacing: -0.3px;
}
.comm-file-wrap .fileName {
  display: none;
  margin: 0 10px 0 0;
}
/* checkbox */
.comm-check-wrap input {
  position: absolute;
  width: 0;
  height: 0;
  margin: 0;
  padding: 0;
  z-index: -1;
  overflow: hidden;
  opacity: 0;
  vertical-align: top;
}
.comm-check-wrap label {
  display: inline-block;
  height: 24px;
  line-height: 21px;
  padding: 0 0 0 30px;
  font-weight: 300;
  font-size: 15px;
  background: url(//img.godo.co.kr/godomall_v2/common/icon_checkbox_off.png) no-repeat 0 0;
  letter-spacing: -0.04em;
  background-size: auto 24px;
  cursor: pointer;
}
.comm-check-wrap label.focus {
  outline: 1px dotted #333;
}
.comm-check-wrap input:focus {
  outline: 1px dotted #333;
}
.comm-check-wrap input:checked + label {
  background: url(//img.godo.co.kr/godomall_v2/common/icon_checkbox_on.png) no-repeat 0 0;
  background-size: auto 24px;
}
.comm-check-wrap input:disabled + label {
  background: url(//img.godo.co.kr/godomall_v2/common/icon_checkbox_on_disabled.png) no-repeat 0 0;
}

/* TOP Button */
.btn-top {
  display: none;
}
.btn-top.re-fixed {
  display: block;
  position: fixed;
  bottom: 47px;
  right: 50%;
  margin-right: -685px;
  display: block;
  width: 60px;
  height: 60px;
  background: url(//img.godo.co.kr/godomall_v2/main/btn_top.png) no-repeat 0 0;
  background-size: 100%;
}
.btn-top.re-fixed.bottom {
  position: absolute;
  bottom: 357px;
}

/* common sub title */
.h3-title-wrap {
  width: 980px;
  margin: 0 auto;
}
.h3-title-wrap .title-box {
  min-width: 100%;
  border-bottom: 1px solid #111;
  padding-bottom: 40px;
}
.h3-title-wrap .title-box h3 {
  position: relative;
  width: 980px;
  display: block;
  font-weight: 700;
  font-size: 50px;
  color: #111;
  letter-spacing: -0.038em;
}
.h3-title-wrap .title-box h3:after {
  display: block;
  content: '';
  position: absolute;
  top: -89px;
  right: 0;
  width: 394px;
  height: 286px;
  background: url(//img.godo.co.kr/godomall_v2/solution/img_top_cnt.png) no-repeat 0 0;
  background-size: 100%;
}
.h3-title-wrap .title-box span {
  display: block;
  margin-top: 20px;
  font-size: 20px;
  color: #454545;
  letter-spacing: -0.03em;
}

/* 회원 */
.type-bg .comm-header-wrap .header {
  border-bottom: 1px solid #e6e6e6;
  background: #fff;
}

/* 구버전 gnb 적용페이지 대응 */
.main_header.old-gnb {
  margin-top: -158px;
}
#top_header.old-gnb {
  margin-top: -158px;
}

/* [1차개편안 적용] common - phr */
.beta .comm-gnb-wrap .gnb-button-wrap a.btn-gnb-free {
  animation: none;
  background: #f7444e !important;
}
.beta .comm-gnb-wrap .gnb-button-wrap a.btn-gnb-free:hover {
  background: #d23a42 !important;
}
.beta .comm-gnb-wrap .comm-gnb > .depth-1 > li > .depth-2-wrap .depth-2 {
  position: relative;
}
.beta .comm-gnb-wrap .comm-gnb > .depth-1 > li.type-beta > .depth-2-wrap .depth-2 ul:last-child {
  position: absolute;
  top: 110px;
  right: 100px;
}
.beta .comm-gnb-wrap .gnb-button-wrap a.btn-gnb-login {
  width: 90px;
  color: #111;
  background: none;
}
.beta .btn-shop {
  animation: none;
  background: #f7444e !important;
}
.beta .btn-shop:hover {
  background: #d23a42 !important;
}

.hidden {
  display: none;
}
.wrapper-box {
  min-width: 980px;
  position: relative;
  padding-top: 158px;
}
body.type-pdNone {
  padding-top: 0;
}
.guide-wrap {
  width: 1200px;
  margin: 0 auto;
}
@media screen and (max-width: 750px) {
  .guide-wrap {
    width: 900px;
  }
}
.guide-line {
  width: 980px;
  margin: 0 auto;
}

/* slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}
.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}
.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.slick-track:before,
.slick-track:after {
  display: table;
  content: '';
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}
.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}
[dir='rtl'] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
  display: none;
}
.slick-dots {
  text-align: center;
}
.slick-dots li {
  display: inline-block;
}
.slick-dots li button {
  display: block;
  width: 20px;
  height: 20px;
  font-size: 0;
  border: 0;
}
.slick-dots li.slick-active button {
}

/* common text */
.txt-title-box {
  text-align: center;
}
.txt-title-box span {
  position: relative;
  display: inline-block;
  font-weight: 700;
  font-size: 40px;
  color: #111;
  letter-spacing: -0.03em;
}
.txt-sb-tit {
  display: block;
  text-align: center;
  margin-top: 20px;
  font-weight: 400;
  font-size: 18px;
  color: #454545;
}

/* top banner */
.main-top-wrap {
  position: relative;
  margin-top: -158px;
}
.main-top-wrap .top-banner-slide {
  height: 780px;
  text-align: left;
}
.main-top-wrap .top-banner-slide.slick-initialized {
}
.main-top-wrap .top-banner-slide .banner-slide:focus {
  outline: 0;
}
.main-top-wrap .top-banner-slide .banner-slide {
  height: 780px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 40px;
}
.main-top-wrap .top-banner-slide .banner-slide .text-wrap {
  width: 900px;
  margin: 0 auto;
}
.main-top-wrap .top-banner-slide .banner-slide .text-wrap a:focus {
  outline: 0;
}
.main-top-wrap .top-banner-slide .banner-slide .text-wrap .top-txt-1 {
  display: block;
  padding-top: 290px;
  font-weight: 700;
  font-size: 24px;
  color: #111;
  letter-spacing: -0.016em;
}
.main-top-wrap .top-banner-slide .banner-slide .text-wrap .top-txt-2 {
  display: block;
  margin-top: -8px;
  line-height: 70px;
  font-weight: 700;
  font-size: 50px;
  color: #111;
  letter-spacing: -0.55px;
}
.main-top-wrap .top-banner-slide .banner-slide .text-wrap .top-txt-2 strong {
  font-family: 'Roboto';
}
.main-top-wrap .top-banner-slide .banner-slide .text-wrap .top-txt-3 {
  display: block;
  margin-top: 7px;
  line-height: 28px;
  font-weight: 300;
  font-size: 18px;
  color: #111;
  letter-spacing: -0.03em;
}
.main-top-wrap .top-banner-slide .btn-shop {
  margin-top: 30px;
  width: 208px;
}
.main-top-wrap .top-banner-slide .btn-shop span {
  background: none;
  padding: 0;
}
.main-top-wrap .top-banner-slide .btn-event {
  display: inline-block;
  height: 60px;
  line-height: 60px;
  padding: 0 30px 0 31px;
  margin-left: 8px;
  text-align: center;
  box-sizing: border-box;
  border-radius: 30px;
  border: 1px solid #333;
}
.main-top-wrap .top-banner-slide .btn-event span {
  display: inline-block;
  font-weight: 700;
  font-size: 18px;
  color: #333;
}
.main-top-wrap .top-banner-slide .btn-detail {
  display: inline-block;
  height: 60px;
  line-height: 60px;
  margin-top: 30px;
  padding: 0 46px 0 45px;
  text-align: center;
  box-sizing: border-box;
  border-radius: 30px;
  background: #f7444e;
}
.main-top-wrap .top-banner-slide .btn-detail:hover {
  background: #d23a42;
}
.main-top-wrap .top-banner-slide .btn-detail span {
  display: inline-block;
  font-weight: 700;
  font-size: 18px;
  color: #fff;
}
.main-top-wrap .top-banner-slide .slick-arrow {
  position: absolute;
  bottom: 53px;
  right: 50%;
  display: block;
  width: 50px;
  height: 50px;
  font-size: 0;
  cursor: pointer;
  z-index: 10;
}
.main-top-wrap .top-banner-slide .slick-prev {
  background: url(https://img.godo.co.kr/godomall_v2/main/btn_mTop_prev.png) no-repeat 0 50%;
  margin-right: -540px;
}
.main-top-wrap .top-banner-slide .slick-next {
  background: url(https://img.godo.co.kr/godomall_v2/main/btn_mTop_next.png) no-repeat 0 50%;
  margin-right: -600px;
}
.main-top-wrap .top-banner-slide .slick-prev:hover {
  background: url(https://img.godo.co.kr/godomall_v2/main/btn_mTop_prev_on.png) no-repeat 0 50%;
  margin-right: -540px;
}
.main-top-wrap .top-banner-slide .slick-next:hover {
  background: url(https://img.godo.co.kr/godomall_v2/main/btn_mTop_next_on.png) no-repeat 0 50%;
  margin-right: -600px;
}
.main-top-paging {
  position: absolute;
  bottom: 47px;
  left: 50%;
  margin-left: -554px;
}
.main-top-wrap .top-banner-slide .main-top-paging li {
  display: inline-block;
  margin: 0 6px;
  cursor: pointer;
}
.main-top-wrap .top-banner-slide .main-top-paging .pg-counts {
  display: inline-block;
  font-family: 'Roboto';
  font-weight: 600;
  font-size: 14px;
  letter-spacing: -0.019em;
  border-bottom: 1px solid rgba(17, 17, 17, 0.2);
  color: #111;
  opacity: 0.2;
}
.main-top-wrap .top-banner-slide .main-top-paging .slick-active .pg-counts {
  color: #111;
  border-bottom: 1px solid #111;
  opacity: 1;
}
.main-top-wrap .main-slide-paStop {
  position: absolute;
  width: 8px;
  height: 12px;
  bottom: 47px;
  left: 50%;
  margin-left: -588px;
}
.main-top-wrap .main-slide-paStop button {
  display: block;
  width: 8px;
  height: 12px;
  font-size: 0;
  position: absolute;
  top: -2px;
  left: 5px;
}
.main-top-wrap .main-slide-paStop .btn-pause {
  width: 8px;
  height: 12px;
  cursor: pointer;
  z-index: 10;
  background: url(https://img.godo.co.kr/godomall_v2/main/btn_main_pause.png) no-repeat 0 50%;
}
.main-top-wrap .main-slide-paStop .btn-play {
  display: none;
  cursor: pointer;
  z-index: 10;
  width: 11px;
  background: url(https://img.godo.co.kr/godomall_v2/main/btn_main_play.png) no-repeat 0 50%;
  background-size: 11px 12px;
}
.banner-progress {
  width: 32px;
  height: 32px;
  position: absolute;
  left: -10px;
  bottom: -10px;
}
.banner-progress span {
  display: block;
  box-sizing: border-box;
  width: 32px;
  height: 32px;
  border: 3px solid #fff;
  border-radius: 50%;
  animation: loading 1.8s linear forwards;
}
/* .main-top-wrap .icon-sDown{position:absolute;bottom:30px;left:50%;margin-left:-28px;}
.main-top-wrap .icon-sDown span{display:block;width:14px;height:8px;margin:2px auto 0;}
.main-top-wrap .icon-sDown .icon-sc-top{background:url(https://img.godo.co.kr/godomall_v2/main/icon_scroll_1.png) no-repeat 0 50%;animation:scrollAni .7s infinite linear alternate;}
.main-top-wrap .icon-sDown .icon-sc-mid{background:url(https://img.godo.co.kr/godomall_v2/main/icon_scroll_2.png) no-repeat 0 50%;animation:scrollAni .7s infinite .2s linear alternate;}
.main-top-wrap .icon-sDown .icon-sc-bot{background:url(https://img.godo.co.kr/godomall_v2/main/icon_scroll_3.png) no-repeat 0 50%;animation:scrollAni .7s infinite .4s linear alternate;}
.main-top-wrap .icon-sDown strong{display:block;margin-top:5px;font-family:'Montserrat';font-weight:700;font-size:12px;color:#111;letter-spacing:0.134em;} */
@-webkit-keyframes scrollAni {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-moz-keyframes scrollAni {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-ms-keyframes scrollAni {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes scrollAni {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes loading-r {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
  }
}
@-moz-keyframes loading-r {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
  }
}
@-ms-keyframes loading-r {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
  }
}
@keyframes loading-r {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
  }
}
@-webkit-keyframes loading-l {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
  }
}
@-moz-keyframes loading-l {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
  }
}
@-ms-keyframes loading-l {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
  }
}
@keyframes loading-l {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
  }
}
.pg-progress {
  position: absolute;
  left: -9px;
  bottom: -9px;
  width: 36px;
  height: 36px;
  line-height: 36px;
  background: none;
  margin: 0 auto;
  box-shadow: none;
  box-sizing: border-box;
}
body.type-ie-9 .pg-progress:after {
  border: 4px solid #111;
}
.pg-progress:after {
  content: '';
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 4px solid #fff;
  position: absolute;
  top: 0;
  left: 0;
  box-sizing: border-box;
}
.pg-progress > span {
  width: 50%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  z-index: 1;
  transform: rotate(-90deg);
}
.pg-progress .progress-left {
  left: 9px;
  bottom: -9px;
}
.pg-progress.ie .progress-left {
  left: 9.5px;
}
.pg-progress .progress-bar {
  width: 100%;
  height: 100%;
  background: none;
  border-width: 4px;
  border-style: solid;
  position: absolute;
  color: #111;
  box-sizing: border-box;
  top: 0;
}
.pg-progress .progress-left .progress-bar {
  left: 100%;
  border-top-right-radius: 80px;
  border-bottom-right-radius: 80px;
  border-left: 0;
  -webkit-transform-origin: center left;
  transform-origin: center left;
}
.pg-progress.on .progress-left .progress-bar {
  animation: loading-l 1.9s linear forwards 2s;
}
.pg-progress .progress-left .progress-bar.pause {
  animation-play-state: paused;
}
.pg-progress .progress-right {
  left: 9px;
  top: -9px;
}
.pg-progress .progress-right .progress-bar {
  left: -100%;
  border-top-left-radius: 80px;
  border-bottom-left-radius: 80px;
  border-right: 0;
  -webkit-transform-origin: center right;
  transform-origin: center right;
}
.pg-progress.on .progress-right .progress-bar {
  animation: loading-r 2s linear forwards;
  animation-play-state: ;
}
.pg-progress .progress-right .progress-bar.pause {
  animation-play-state: paused;
}

/* 포트폴리오 */
.section-1 {
  padding: 90px 0 68px;
  background: #f7f7f7;
}
.section-1 .txt-title-box em:before {
  display: block;
  content: '';
  width: 10px;
  height: 13px;
  background: url(https://img.godo.co.kr/godomall_v2/main/icon_cl_1_3.png) no-repeat 0 0;
  position: absolute;
  top: -13px;
  right: 0;
  background-size: 100%;
}
.section-1 .txt-title-box em:after {
  display: block;
  content: '';
  width: 15px;
  height: 19px;
  background: url(https://img.godo.co.kr/godomall_v2/main/icon_cl_1_4.png) no-repeat 0 0;
  position: absolute;
  top: -4px;
  right: -20px;
  background-size: 100%;
}
.pf-cont-wrap .list-box {
  overflow: hidden;
}
.pf-cont-wrap .list-box .list-slide-wrap {
  width: 980px;
  margin: 0 auto;
  padding: 26px 0;
  display: none;
}
.pf-cont-wrap .list-box .list-slide-wrap.slick-initialized {
  display: block;
}
.pf-cont-wrap .list-box .list-slide-wrap .slick-list {
  overflow: inherit;
}
.pf-cont-wrap .pf-tab {
  text-align: center;
  margin-top: 57px;
}
.pf-cont-wrap .pf-tab li {
  display: inline-block;
}
.pf-cont-wrap .pf-tab li a {
  display: inline-block;
  margin: 0 16px;
  font-weight: 400;
  line-height: 16px;
  font-size: 18px;
  color: #737373;
  vertical-align: middle;
  letter-spacing: -0.032em;
}
.pf-cont-wrap .pf-tab li.on a {
  font-weight: 700;
  color: #111;
  text-decoration: underline;
}
.pf-cont-wrap .slick-list .slick-track {
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
}
.pf-cont-wrap .pf-list {
}
.pf-cont-wrap .pf-list .list-box {
  display: none;
  padding: 10px 0 5px;
}
.pf-cont-wrap .pf-list .list-item {
  display: block;
  position: relative;
  padding: 12px;
  width: 204px;
  height: 204px;
  box-sizing: border-box;
  float: left;
  -webkit-transform: translateZ(0.1px);
  -moz-transform: translateZ(0.1px);
  -ms-transform: translateZ(0.1px);
  transform: translateZ(0.1px);
}
.pf-cont-wrap .pf-list .list-item:focus {
  outline: 0;
}
.pf-cont-wrap .pf-list .list-item img {
  width: 180px;
  height: 180px;
  text-align: center;
  vertical-align: middle;
}
.pf-cont-wrap .pf-list .list-item .txt-brand,
.pf-cont-wrap .pf-list .list-item.slick-slide:hover .txt-brand {
  display: none;
}
.pf-cont-wrap .pf-list .list-item.slick-active:hover .txt-brand {
  display: inline-block;
  min-width: 124px;
  max-height: 56px;
  box-sizing: border-box;
  text-align: center;
  padding: 7px 17px;
  border-radius: 28px;
  font-weight: 400;
  line-height: 20px;
  font-size: 14px;
  color: #fff;
  background: #f7444e;
  letter-spacing: -0.03em;
  position: absolute;
  left: 50%;
  bottom: -10px;
  transform: translate(-50%, 0);
}
.pf-cont-wrap .pf-list .list-item.slick-slide:before {
  box-shadow: none;
}
.pf-cont-wrap .pf-list .list-item.slick-slide:after {
  display: block;
  content: '';
  width: 180px;
  height: 180px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.9);
  position: absolute;
  top: 12px;
  left: 12px;
  z-index: 10;
}
.pf-cont-wrap .pf-list .list-item.slick-active:after {
  display: none;
}
.pf-cont-wrap .pf-list .list-item.slick-active:hover:before {
  display: block;
  content: '';
  width: 180px;
  height: 180px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0);
  box-shadow: 0 15px 22px rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 12px;
  left: 12px;
}
.pf-cont-wrap .pf-list .list-item.pd-active:after {
  display: block;
  content: '';
  width: 180px;
  height: 180px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.9);
  position: absolute;
  top: 12px;
  left: 12px;
  z-index: 10;
}
.pf-cont-wrap .slick-arrow {
  display: block;
  width: 50px;
  height: 51px;
  position: absolute;
  top: 50%;
  font-size: 0;
  cursor: pointer;
  z-index: 10;
}
.pf-cont-wrap .slick-prev {
  background: url(https://img.godo.co.kr/godomall_v2/main/btn_pf_prev.png) no-repeat 0 0;
  left: -25px;
  margin-top: -25px;
}
.pf-cont-wrap .slick-next {
  background: url(https://img.godo.co.kr/godomall_v2/main/btn_pf_next.png) no-repeat 0 0;
  right: -25px;
  margin-top: -25px;
}
.pf-cont-wrap .slick-prev:hover {
  background: url(https://img.godo.co.kr/godomall_v2/main/btn_pf_prev_on.png) no-repeat 0 0;
}
.pf-cont-wrap .slick-next:hover {
  background: url(https://img.godo.co.kr/godomall_v2/main/btn_pf_next_on.png) no-repeat 0 0;
}

/* 쇼핑몰 운영 서비스 */
.section-2 {
  height: 1300px;
  box-sizing: border-box;
  padding-top: 70px;
  background: #eeeff4;
}
.section-2 .txt-title-box {
  text-align: left;
  margin-top: 70px;
}
.section-2 .txt-title-box span {
  line-height: 52px;
}
.section-2 .txt-title-box span.on:before {
}
.section-2 .txt-title-box span:after {
  display: block;
  content: '';
  width: 90px;
  height: 75px;
  background: url(https://img.godo.co.kr/godomall_v2/main/icon_cl_2_2.png) no-repeat 0 0;
  position: absolute;
  top: 78px;
  right: -77px;
  background-size: 100%;
}
.section-2 .txt-title-box em:before {
  display: block;
  content: '';
  width: 51px;
  height: 39px;
  background: url(https://img.godo.co.kr/godomall_v2/main/icon_cl_2_3.png) no-repeat 0 0;
  position: absolute;
  top: 35px;
  right: -126px;
  background-size: 100%;
}
.section-2 .txt-sb-tit {
  text-align: left;
  line-height: 30px;
}
.section-2 .guide-wrap {
  position: relative;
}
.section-2 .service-cont .item-box {
  -webkit-transform: translate(0px, 200px);
  -moz-transform: translate(0px, 200px);
  -ms-transform: translate(0px, 200px);
  transform: translate(0px, 200px);
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}
.section-2 .service-cont .item-box {
  display: block;
  width: 282px;
  height: 440px;
  padding: 30px 29px;
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 18px 18px 13px rgba(0, 0, 0, 0.07);
  background: #fff;
  opacity: 1;
  transition: all 1s;
  text-align: left;
}
.section-2 .service-cont .item-box.on {
  -webkit-transform: translate(0px, 0px);
  -moz-transform: translate(0px, 0px);
  -ms-transform: translate(0px, 0px);
  transform: translate(0px, 0px);
  opacity: 1;
}
.section-2 .service-cont .item-box:hover {
  -webkit-transform: translate(0px, -20px);
  -moz-transform: translate(0px, -20px);
  -ms-transform: translate(0px, -20px);
  transform: translate(0px, -20px);
}
.section-2 .service-cont .item-box .svc-txt-1 {
  display: block;
  font-weight: 400;
  font-size: 20px;
  color: #111;
  letter-spacing: -0.03em;
}
.section-2 .service-cont .item-box .svc-txt-2 {
  display: block;
  margin-top: 10px;
  font-weight: 300;
  font-size: 14px;
  letter-spacing: -0.2px;
  line-height: 25px;
  color: #454545;
}
.section-2 .service-cont .item-box .btn-detail {
  display: inline-block;
  margin-top: 20px;
  padding-right: 20px;
  font-weight: 400;
  font-size: 15px;
  color: #f7444e;
  letter-spacing: -0.03em;
  background: url(https://img.godo.co.kr/godomall_v2/main/icon_arrow.png) no-repeat 100% 6px;
  background-size: 17px 9px;
}
.section-2 .service-cont .item-box.pot-1 {
  position: absolute;
  left: 815px;
  top: -70px;
}
.section-2 .service-cont .item-box.pot-1::before {
  position: absolute;
  display: block;
  content: ' ';
  left: 0;
  right: 0;
  bottom: -20px;
  height: 520px;
  background: url(/img/bg_service_1.png) no-repeat 100% 100%;
  background-size: 100%;
  background-position: center bottom;
}

.section-2 .service-cont .item-box.pot-2 {
  position: absolute;
  left: 473px;
  top: 132px;
}
.section-2 .service-cont .item-box.pot-2::before {
  position: absolute;
  display: block;
  content: ' ';
  left: 0;
  right: 0;
  bottom: -20px;
  height: 520px;
  background: url(/img/bg_service_2.png) no-repeat 100% 100%;
  background-size: 100%;
  background-position: center bottom;
}

.section-2 .service-cont .item-box.pot-3 {
  position: absolute;
  left: 130px;
  top: 390px;
}

@media all and (max-width: 1000px) {
  .section-2 .service-cont a {
    margin-left: -165px;
  }
}

.section-2 .service-cont .item-box.pot-3::before {
  position: absolute;
  display: block;
  content: ' ';
  left: 0;
  right: 0;
  bottom: -20px;
  height: 520px;
  background: url(/img/bg_service_3.png) no-repeat 100% 100%;
  background-size: 100%;
  background-position: center bottom;
}
.section-2 .service-cont .item-box.pot-4 {
  position: absolute;
  left: 815px;
  top: 430px;
}
.section-2 .service-cont .item-box.pot-4::before {
  position: absolute;
  display: block;
  content: ' ';
  left: 0;
  right: 0;
  bottom: -20px;
  height: 520px;
  background: url(/img/bg_service_4.png) no-repeat 100% 100%;
  background-size: 100%;
  background-position: center bottom;
}
.section-2 .service-cont .item-box.pot-5 {
  position: absolute;
  left: 473px;
  top: 632px;
}
.section-2 .service-cont .item-box.pot-5::before {
  position: absolute;
  display: block;
  content: ' ';
  left: 0;
  right: 0;
  bottom: -20px;
  height: 520px;
  background: url(/img/bg_service_5.png) no-repeat 100% 100%;
  background-size: 100%;
  background-position: center bottom;
}

/* 대표 서비스 */
.section-3 {
  padding: 204px 0 80px;
  box-sizing: border-box;
  background: #fff;
}
.section-3 .txt-title-box em:before {
  display: block;
  content: '';
  width: 56px;
  height: 54px;
  background: url(https://img.godo.co.kr/godomall_v2/main/icon_cl_3_3.png) no-repeat 0 0;
  position: absolute;
  top: -66px;
  left: 75px;
  background-size: 100%;
}
.section-3 .contents-box .support-list {
  margin-top: 49px;
}
.section-3 .contents-box .support-list:after {
  display: block;
  content: '';
  clear: both;
}
.section-3 .contents-box .support-list li {
  float: left;
  width: 400px;
}
.section-3 .contents-box .support-list li a {
  display: block;
}
.section-3 .contents-box .support-list li a .img-box {
  width: 400px;
  height: 320px;
  overflow: hidden;
}
.section-3 .contents-box .support-list li a .img-box > span {
  display: block;
  width: 100%;
  height: 100%;
  background-position: 50% 50%;
  background-size: 400px 320px;
  background-repeat: no-repeat;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}
.section-3 .contents-box .support-list li a:hover .img-box > span {
  -webkit-transform: scale(1.1) rotate(0.001deg);
  -moz-transform: scale(1.1) rotate(0.001deg);
  -ms-transform: scale(1.1) rotate(0.001deg);
  transform: scale(1.1) rotate(0.001deg);
}
.section-3 .contents-box .support-list li a .text-wrap {
  margin-top: 29px;
  padding: 0 29px;
}
.section-3 .contents-box .support-list li a .cont-tit {
  display: block;
  line-height: 32px;
  font-weight: 400;
  font-size: 20px;
  color: #111;
  letter-spacing: -0.03em;
}
.section-3 .contents-box .support-list li a .cont-txt-1 {
  display: block;
  line-height: 25px;
  margin-top: 8px;
  font-weight: 300;
  font-size: 15px;
  color: #454545;
}
.section-3 .contents-box .support-list li a .cont-txt-2 {
  display: inline-block;
  margin-top: 20px;
  padding-right: 20px;
  font-weight: 400;
  font-size: 15px;
  color: #f7444e;
  letter-spacing: -0.03em;
  background: url(https://img.godo.co.kr/godomall_v2/main/icon_arrow.png) no-repeat 100% 50%;
  transition: padding 0.3s;
  background-size: 17px 9px;
}
.section-3 .contents-box .support-list li a:hover .cont-txt-2 {
  padding-right: 30px;
}

/* 배너 */
.banner-wrap {
  text-align: center;
  padding: 32px 0 58px;
  background: url(https://img.godo.co.kr/godomall_v2/main/bg_banner.png) no-repeat 50% 50%;
}
.banner-wrap .txt-sb-tit {
  display: block;
  font-weight: 400;
  font-size: 20px;
  color: #111;
  letter-spacing: -0.02em;
}
.banner-wrap .txt-title-box {
  font-weight: 600;
  font-size: 48px;
  color: #111;
  letter-spacing: -0.048em;
}
.banner-wrap .btn-shop {
  display: inline-block;
  margin-top: 30px;
}

/* svg */
.txt-title-box svg {
  overflow: inherit;
}
.section-1 .txt-title-box .draw-circle {
  display: none;
}
.section-1 .txt-title-box.ani .draw-circle {
  display: block;
  stroke-width: 4;
  stroke: #f7444e;
  fill: none;
  stroke-dasharray: 1000;
  animation: circle 1.5s linear;
  -webkit-animation-fill-mode: both;
}
.section-1 .txt-title-box .line-1 {
  position: absolute;
  top: -10px;
  left: 323px;
  overflow: inherit;
}
.section-1 .txt-title-box .line-2 {
  position: absolute;
  bottom: -12px;
  left: 455px;
}
.section-1 .txt-title-box .line-2 .draw-circle {
  animation-delay: 1s;
}
.section-1 .txt-title-box .line-3 {
  position: absolute;
  bottom: -7px;
  left: 496px;
}
.section-1 .txt-title-box .line-3 .draw-circle {
  animation-delay: 1.2s;
}
.section-2 .txt-title-box svg {
  position: absolute;
  top: 45px;
  left: 0;
  width: 165px;
  overflow: hidden;
}
.section-2 .txt-title-box .draw-line {
  display: none;
}
.section-2 .txt-title-box.ani .draw-line {
  display: block;
  stroke-width: 4;
  stroke: #f7444e;
  fill: none;
  stroke-dasharray: 1000;
  animation: line 1.5s linear;
  -webkit-animation-fill-mode: both;
}
.section-3 .txt-title-box .draw-line {
  display: none;
}
.section-3 .txt-title-box.ani .draw-line {
  display: block;
  stroke-width: 3;
  stroke: #f7444e;
  fill: none;
  stroke-dasharray: 1000;
  animation: line 1.5s linear;
  -webkit-animation-fill-mode: both;
}
.section-3 .txt-title-box .line-1 {
  position: absolute;
  top: -15px;
  left: 0;
}
.section-3 .txt-title-box .line-2 {
  position: absolute;
  bottom: 0px;
  left: 0;
}
.section-3 .txt-title-box .line-2 .draw-line {
  animation-delay: 1s;
}

@-webkit-keyframes circle {
  from {
    stroke-dashoffset: 1000;
  }
  to {
    stroke-dashoffset: 0;
  }
}
@keyframes circle {
  from {
    stroke-dashoffset: 1000;
  }
  to {
    stroke-dashoffset: 0;
  }
}

@-webkit-keyframes line {
  from {
    stroke-dashoffset: 1000;
  }
  to {
    stroke-dashoffset: 0;
  }
}
@keyframes line {
  from {
    stroke-dashoffset: 1000;
  }
  to {
    stroke-dashoffset: 0;
  }
}

.scrollMotion {
  opacity: 0;
  transition: all 1s;
  -webkit-transform: translate(0, 50px);
  -moz-transform: translate(0, 50px);
  -ms-transform: translate(0, 50px);
  transform: translate(0, 50px);
}
.scrollMotion.on {
  opacity: 1;
  -webkit-transform: translate(0, 0);
  -moz-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
}

/* [1차개편안 적용] main - phr */
.beta .banner-wrap .btn-shop {
  width: 205px;
  height: 64px;
}
.beta .btn-shop span {
}

/* 201201 사이트맵 추가 - kbn */
.sitemap-wrap {
  margin-top: -51px;
  overflow: hidden;
  background: #f7f7f7;
}
.sitemap-wrap .h3-title-wrap .title-box h3:after {
  display: none;
}
.sitemap-wrap .sitemap-content {
  padding: 90px 0 0 0;
}
.sitemap-wrap .sitemap-content .blank,
.sitemap-wrap .sitemap-content .link {
  position: Relative;
  font-family: 'Roboto', 'Noto Sans KR';
  letter-spacing: -0.3px;
}
.sitemap-wrap .sitemap-content .blank:after {
  content: '';
  position: Absolute;
  top: 50%;
  right: -24px;
  margin: -8px 0 0 0;
  width: 16px;
  height: 16px;
  background: url('https://img.godo.co.kr/godomall_v2/main/icon_sm_blank.png') no-repeat 0 0;
  background-size: contain;
}
.sitemap-wrap .sitemap-content .link:after {
  content: '';
  position: absolute;
  top: 50%;
  right: -24px;
  margin: -9px 0 0 0;
  width: 11px;
  height: 18px;
  background: url('https://img.godo.co.kr/godomall_v2/main/img_sm_arrow.png') no-repeat 0 0;
  background-size: contain;
}
.sitemap-wrap .site-block-wrap {
  padding: 70px 0 60px 0;
}
.site-block + .site-block {
  margin: 20px 0 0 0;
}

.site-list-block-1 {
  background: #ffffff;
  border-radius: 10px;
  padding: 30px 0 50px 30px;
  width: 224px;
  height: 700px;
  vertical-align: top;
  box-sizing: border-box;
  display: inline-block;
  margin: 0 0 0 17px;
}
.site-list-block-1:first-of-type {
  margin: 0;
}
.site-list-block-1 .site-list-tit a {
  font-size: 26px;
  color: #111111;
  font-weight: bold;
}
.site-list-block-1 .site-list-tit a:after {
  content: '';
  display: inline-block;
  margin: 0 0 0 8px;
  width: 11px;
  height: 18px;
  background: url('https://img.godo.co.kr/godomall_v2/main/img_sm_arrow.png') no-repeat center center;
  background-size: contain;
}
.site-list-block-1 .site-list-tit a:hover {
  color: #f7444e;
}
.site-list-block-1 .site-list-tit a:hover:after {
  background: url('https://img.godo.co.kr/godomall_v2/main/img_sm_arrow_on.png') no-repeat 0 0;
  background-size: contain;
}

.site-list-block-1 .site-list-cont > ul > li:first-child {
  margin: 35px 0 0 0;
}
.site-list-block-1 .site-list-cont > ul > li + li {
  margin: 25px 0 0 0;
}
.site-list-block-1 .site-list-cont > ul > li:hover .site-list-1depth > a {
  color: #f7444e;
  text-decoration: underline;
}
.site-list-block-1 .site-list-cont .site-list-1depth > a {
  font-size: 16px;
  font-weight: 500;
}
.site-list-block-1 .site-list-cont .site-list-2depth > li {
  margin: 15px 0 0 0;
}
.site-list-block-1 .site-list-cont .site-list-2depth > li > a {
  font-size: 15px;
  color: #454545;
}
.site-list-block-1 .site-list-cont .site-list-2depth > li > a.blank:after {
  content: '';
  position: absolute;
  top: 50%;
  right: -15px;
  width: 10px;
  height: 10px;
  background: url('https://img.godo.co.kr/godomall_v2/main/icon_new_window.png') no-repeat 0 0;
  background-size: contain;
  margin: -5px 0 0 0;
}
.site-list-block-1 .site-list-cont .site-list-2depth > li > a:hover {
  text-decoration: underline;
}
.site-list-block-1 .site-list-cont .site-list-2depth > li > a:before {
  content: '-';
  display: inline-block;
  margin: 0 0 2px 0;
}

.site-list-block-2 {
  padding: 40px 0;
  border-bottom: 1px solid #e6e6e6;
  font-family: 'Roboto', 'Noto Sans KR';
}
.site-list-block-2 .site-list-tit {
  font-size: 26px;
  font-weight: bold;
  width: 240px;
  display: inline-block;
  color: #111111;
}
.site-list-block-2:last-child {
  border-bottom: 0;
}
.site-list-block-2:hover .site-list-tit > a {
  color: #f7444e;
}
.site-list-block-2:hover .site-list-tit > a.link:after {
  background: url('https://img.godo.co.kr/godomall_v2/main/img_sm_arrow_on.png') no-repeat 0 0;
  background-size: contain;
}
.site-list-block-2.col-4 .site-list-tit {
  margin: 0 215px 0 0;
  width: auto;
}
.site-list-block-2.col-4 .site-list-tit > a:hover {
  color: #f7444e !important;
}
.site-list-block-2.col-4:hover .site-list-tit > a {
  color: #111111;
}
.site-list-block-2 .site-list-cont {
  display: inline-block;
}
.site-list-block-2 .site-list-cont li {
  display: inline-block;
  width: 195px;
  margin: 0 0 0 -6px;
}
.site-list-block-2 .site-list-cont li > a {
  font-size: 18px;
  font-weight: 500;
}
.site-list-block-2 .site-list-cont li > a:hover {
  color: #f7444e;
  text-decoration: underline;
}
</style>
